import React, { useEffect, useRef } from 'react';
import Hls from 'hls.js';
import { useDispatch, useSelector } from 'react-redux';
import { selectShowLiveFeed } from '../../redux/slices/streamSlice';
import { streamUrlBase } from '../../redux/slices/apiConfig';

const LiveFeedPlayer = ({ camera, host}) => {
  const videoRef = useRef(null);
  const showLiveFeed = useSelector(selectShowLiveFeed);
  const url = `${streamUrlBase}cam${camera}/stream${camera}.m3u8`;
  console.log(url);

  useEffect(() => {
    const video = videoRef.current;

    const loadVideo = () => {
      if (Hls.isSupported()) {
        const hls = new Hls({
          xhrSetup: (xhr) => {
            xhr.setRequestHeader('X-Dynamic-Host', host);
          },
        });
        hls.loadSource(url);
        hls.attachMedia(video);
        hls.on(Hls.Events.MANIFEST_PARSED, () => {
          if (showLiveFeed) {
            video.play();
          }
        });
      } else if (video.canPlayType('application/vnd.apple.mpegurl')) {
        video.src = url;
        video.addEventListener('loadedmetadata', () => {
          if (showLiveFeed) {
            video.play();
          }
        });
      }
    };

    loadVideo();

    return () => {
      // Pause and unload the video source
      video.pause();
      if (Hls.isSupported()) {
        const hls = Hls.isSupported() ? new Hls() : null;
        if (hls) {
          hls.destroy();
        }
      }
    };
  }, [url, showLiveFeed, host]);

  useEffect(() => {
    const video = videoRef.current;
    video.muted = true; // Mute the video
  }, []);

  useEffect(() => {
    // Cleanup and pause video when component visibility changes
    if (!showLiveFeed) {
      const video = videoRef.current;
      video.pause();
    }
  }, [showLiveFeed]);

  return <video ref={videoRef} style={{ width: '100%', height: '100%', borderRadius: "10px" }} />;
};

export default LiveFeedPlayer;

import React, { useState } from 'react';
import { Snackbar, Alert, useTheme } from '@mui/material';
import { tokens } from "../../theme";
import { useSelector, useDispatch } from 'react-redux';
import { selectNotification ,selectNotificationOpen, closeNotification} from '../../redux/slices/notificationSlice';

const Notification = ({ duration = 3000 }) => {
    const isOpen = useSelector(selectNotificationOpen);
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const notification = useSelector(selectNotification);

    const handleClose = (event, reason) => {
        if (reason === 'clickaway') {
        return;
        }
        dispatch(closeNotification());
    };

    return (
        <Snackbar
        open={isOpen}
        autoHideDuration={duration}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
        <Alert
            onClose={handleClose}
            severity={notification.severity}
            sx={{
            width: '100%',
            padding: '20px',
            fontSize: '1.25rem',
            backgroundColor: notification.severity === 'error' ? colors.redAccent[500] : colors.blueAccent[100],
            color: colors.grey[100],
            '& .MuiAlert-icon': {
                color: colors.grey[100],
                fontSize: '1.5rem',
            },
            }}
        >
            {notification.message}
        </Alert>
        </Snackbar>
    );
};

export default Notification;
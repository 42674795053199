import React, { useState } from "react";
import { Box, Typography, Input}
from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import DropDownBox from "./DropDownBox";
import { changeSettingValue, selectSpecificSettingValue, translateSubResolution } from "../../redux/slices/controllerSlice";

const General = ({findModified}) => {
  const dispatch = useDispatch();
  
  const text = {
    "@media (max-width: 1080px)": {
      fontSize: "12px",
    },
  };

  // array from 1-30
  const subFPSOptions = Array.from({ length: 30 }, (_, i) => i + 1);
  const mainFPSOptions = Array.from({ length: 30 }, (_, i) => i + 1);
  const iframeOptions = Array.from({ length: 30 }, (_,i) => i + 1);
  const bitControlOptions = ['VBR', 'CBR'];
  const bitOptions = ['1024', '1280', '1536', '1792', '2048', '4096', '6144']
  const subResolutionOptions = ['352*240(CIF)', '640*480(VGA)', '704*480(D1)']
  const codecOptions = ['H.264', 'H.265', 'H.264H']

  const [mainstreamFPSAnchorEl, setMainstreamFPSAnchorEl] = useState(null);
  const [substreamFPSAnchorEl, setSubstreamFPSAnchorEl] = useState(null);
  const [mainstreamBitRateControlAnchorEl, setMainstreamBitRateControlAnchorEl] = useState(null);
  const [substreamResolutionAnchorEl, setSubstreamResolutionAnchorEl] = useState(null);
  const [mainstreamBitRateAnchorEl, setMainstreamBitRateAnchorEl] = useState(null);
  const [mainstreamCodecAnchorEl, setMainstreamCodecAnchorEl] = useState(null);
  const [substreamCodecAnchorEl, setSubstreamCodecAnchorEl] = useState(null);
  const [mainstreamIFrameAnchorEl, setMainstreamIFrameAnchorEL] = useState(null);

 
  const handleMainFPSChange = (event, newValue) => {
    dispatch(changeSettingValue({ title: 'MainFormat[0].Video.FPS', value: newValue }));
    findModified();
  };

  const handleMainIFrameChange = (event, newValue) => {
    dispatch(changeSettingValue({ title: 'MainFormat[0].Video.GOP', value: newValue}));
    findModified();
  }


  const handleSubFPSChange = (event, newValue) => {
    dispatch(changeSettingValue({ title: 'ExtraFormat[0].Video.FPS', value: newValue }));
    findModified();
  };

  const handleMainCodecChange = (event, newValue) => {
    dispatch(changeSettingValue({ title: 'MainFormat[0].Video.Compression', value: newValue }));
    findModified();
  }

  const handleSubCodecChange = (event, newValue) => {
    dispatch(changeSettingValue({ title: 'ExtraFormat[0].Video.Compression', value: newValue }));
    findModified();
  }

  const handleMainBitRateControlChange = (event, newValue) => {
    dispatch(changeSettingValue({ title: 'BitRateControl', value: newValue}));
    findModified();
  };

  const handleSubResolutionChange = (event, newValue) => {
    newValue = translateSubResolution(newValue);

    dispatch(changeSettingValue({ title: 'ExtraFormat[0].Video.CustomResolutionName', value: newValue}));
    findModified();
  }

  const handleMainBitRateChange = (event, newValue) => {
    dispatch(changeSettingValue({ title: 'BitRate', value: newValue}));
    findModified();
  }


  const handleDropdownClick = (event, anchorElSetter) => {
    anchorElSetter(event.currentTarget);
  };

  const handleTimeRangeChange = (e) => {
    dispatch(changeSettingValue({ title: 'Time', value: e.target.value}));
    findModified();
  };

  const handleChannelTitleChange = (e) => {
    dispatch(changeSettingValue({ title: 'ChannelTitle', value: e.target.value}));
    findModified();
  };

  const handleDropdownClose = (anchorElSetter) => {
    anchorElSetter(null);
  };

  return (
    <Box>
      <Box display="flex" width="100%" gap="20px" mb="40px" sx={{ '@media (max-width: 600px)': { display: 'flex', flexDirection: "column" } }}>
        <Box width="100%" display="flex" flexDirection="column" gap="10px">
          <Typography variant="h6" sx={text}>Schedule</Typography>
          <Box display={"flex"} justifyContent={"space-between"} padding="10px" border={1} borderRadius="10px" borderColor="#585858">
          <Input sx={text} type="text" required={true} value={useSelector((state) => selectSpecificSettingValue(state, "Time"))} onChange={(e) => handleTimeRangeChange(e)} />
          </Box>
        </Box>
        <Box width="100%" display="flex" flexDirection="column" gap="10px">
          <Typography variant="h6" sx={text}>Channel Title</Typography>
          <Box display={"flex"} justifyContent={"space-between"} padding="10px" border={1} borderRadius="10px" borderColor="#585858">
          <Input sx={text} type="text" required={true} value={useSelector((state) => selectSpecificSettingValue(state, "ChannelTitle"))}  onChange={(e) => handleChannelTitleChange(e)} />
          </Box>
        </Box>
      </Box>
      <Box display="flex" width="100%" gap="20px" mb="40px" sx={{ '@media (max-width: 600px)': { display: 'flex', flexDirection: "column" } }}>
        <DropDownBox
          title="Mainstream FPS"
          options={mainFPSOptions}
          anchorEl={mainstreamFPSAnchorEl}
          setAnchorEl={setMainstreamFPSAnchorEl}
          handleDropdownClick={handleDropdownClick}
          handleDropdownClose={handleDropdownClose}
          handleChange={handleMainFPSChange}
          nickname="MainFormat[0].Video.FPS" />
        <DropDownBox
          title="Mainstream iFrame"
          options={iframeOptions}
          anchorEl={mainstreamIFrameAnchorEl}
          setAnchorEl={setMainstreamIFrameAnchorEL}
          handleDropdownClick={handleDropdownClick}
          handleDropdownClose={handleDropdownClose}
          handleChange={handleMainIFrameChange}
          nickname="MainFormat[0].Video.GOP" />
        <DropDownBox
          title="Substream FPS"
          options={subFPSOptions}
          anchorEl={substreamFPSAnchorEl}
          setAnchorEl={setSubstreamFPSAnchorEl}
          handleDropdownClick={handleDropdownClick}
          handleDropdownClose={handleDropdownClose}
          handleChange={handleSubFPSChange} 
          nickname="ExtraFormat[0].Video.FPS"/>
        <DropDownBox
          title="Mainstream Codec"
          options={codecOptions}
          anchorEl={mainstreamCodecAnchorEl}
          setAnchorEl={setMainstreamCodecAnchorEl}
          handleDropdownClick={handleDropdownClick}
          handleDropdownClose={handleDropdownClose}
          handleChange={handleMainCodecChange} 
          nickname="MainFormat[0].Video.Compression"/>
        <DropDownBox
          title="Substream Codec"
          options={codecOptions}
          anchorEl={substreamCodecAnchorEl}
          setAnchorEl={setSubstreamCodecAnchorEl}
          handleDropdownClick={handleDropdownClick}
          handleDropdownClose={handleDropdownClose}
          handleChange={handleSubCodecChange} 
          nickname="ExtraFormat[0].Video.Compression"/>
        <DropDownBox
          title="Mainstream Bitrate Control"
          options={bitControlOptions}
          anchorEl={mainstreamBitRateControlAnchorEl}
          setAnchorEl={setMainstreamBitRateControlAnchorEl}
          handleDropdownClick={handleDropdownClick}
          handleDropdownClose={handleDropdownClose}
          handleChange={handleMainBitRateControlChange} 
          nickname="MainFormat[0].Video.BitRateControl"/>
        <DropDownBox
          title="Substream Resolution"
          options={subResolutionOptions}
          anchorEl={substreamResolutionAnchorEl}
          setAnchorEl={setSubstreamResolutionAnchorEl}
          handleDropdownClick={handleDropdownClick}
          handleDropdownClose={handleDropdownClose}
          handleChange={handleSubResolutionChange} 
          nickname="ExtraFormat[0].Video.CustomResolutionName"/>
        <DropDownBox
          title="Mainstream Bitrate"
          options={bitOptions}
          anchorEl={mainstreamBitRateAnchorEl}
          setAnchorEl={setMainstreamBitRateAnchorEl}
          handleDropdownClick={handleDropdownClick}
          handleDropdownClose={handleDropdownClose}
          handleChange={handleMainBitRateChange} 
          nickname="MainFormat[0].Video.BitRate"/>
      </Box>
    </Box>
  );
};

export default General;
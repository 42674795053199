import { Box, IconButton, useTheme, Typography } from "@mui/material";
import { tokens } from "../../theme";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";
import TableHeading from "./TableHeading";
import Location from "./Location";
import LoadingIcon from "../Loading";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchAllLocations, setSearchTerm, selectSearchTerm, selectData, selectLocationLoading } from '../../redux/slices/locationSlice'


const LocationList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const searchQuery = useSelector(selectSearchTerm);
  const locationDataList = useSelector(selectData);
  const loading = useSelector(selectLocationLoading);

  const handleSearchChange = (event) => {
    dispatch(setSearchTerm(event.target.value));
  };

  const filteredLocations = locationDataList.filter(item =>
    item.location_name.toLowerCase().includes(searchQuery.toLowerCase()) ||
    item.host_ip.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const sortedLocations = filteredLocations.slice().sort((a, b) => a.location_name.localeCompare(b.location_name));

  useEffect(() => {
    dispatch(fetchAllLocations());
  }, [dispatch]);


  return (
    <Box mt={"30px"}>
      <Box display="flex" flexDirection={{ xs: "column", sm: "row" }} justifyContent="space-between"
        alignItems="center"
        p={{ xs: "10px", sm: "20px" }}
        sx={{
          backgroundColor: colors.grey[900],
          borderTopLeftRadius: [20],
          borderTopRightRadius: [20],
          borderBottom: "1px solid #585858"

        }}
      >
        <Typography
          variant="h4"
          fontWeight="medium"
          sx={{
            color: colors.grey[100],
            "@media (max-width: 1080px)": {
              fontSize: "14px"
            }
          }}
        >
          Location List
        </Typography>

        <Box display="flex" gap={"35px"} sx={{ "@media (max-width: 600px)": { gap: "10px", my: "10px" } }} >
          <Box
            display="flex"
            backgroundColor={colors.blueAccent[900]}
            borderRadius="12px"
            pr={{ xs: "40px", sm: "80px" }}
            sx={{ "@media (max-width: 1080px)": { pr: "1px" } }}

          >
            <IconButton type="button" sx={{ color: colors.grey[100] }}>
              <SearchIcon />
            </IconButton>
            <InputBase
              sx={{ color: "white" }}
              placeholder="Search"
              value={searchQuery}
              onChange={handleSearchChange}
            />
          </Box>
        </Box>
      </Box>
      <TableHeading />
      {loading ? (
        <Box display="flex" justifyContent="center" alignItems="center" width="100%" height="50vh">
        <LoadingIcon color={colors.blueAccent[100]} />
      </Box>
        ) : <Location locations={sortedLocations} />}
    </Box>
  );
};

export default LocationList;

import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { storeLocationId, storeLocationName, storeLocationStatus, storeBayCount, storeLocationIp } from "../../redux/slices/locationSlice";
import { setCurrentView } from "../../redux/slices/generalSlice";


const TableStyle = ({ location: { location_id, location_name, cameras_count, is_active, bay_count, host_ip }}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();

  const tablestyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    px: "30px",
    "&:hover, &:active": {
      cursor: "pointer",
    },
    "@media (max-width: 1080px)": {
      px: "20px",
    },
    "@media (max-width: 940px)": {
      px: "10px",
    }
  }
  const numbers = {
    color: colors.blueAccent[300],
    backgroundColor: colors.blueAccent[800],
    border: "1px solid",
    borderColor: colors.blueAccent[700],
    p: "5px",
    px: "25px",
    borderRadius: "80px",
    "@media (max-width: 1080px)": {
      p: "1px",
      px: "12px",
    },
    "@media (max-width: 940px)": {
      px: "5px",
    },
    "@media (max-width: 600px)": {
      my: "5px",
    }
  }
  const text = {
    "@media (max-width: 940px)": {
      fontSize: "12px"
    }
  }
  const gap = {
    "@media (max-width: 940px)": {
      gap: "2px",
    }
  }

  const handleTableStyleClick = async () => {
    dispatch(storeLocationId(location_id))
    dispatch(storeLocationName(location_name))
    dispatch(storeLocationStatus(is_active))
    dispatch(storeLocationIp(host_ip))
    dispatch(storeBayCount(bay_count))
    dispatch(setCurrentView('cameras'))
  };

  return (
    <Grid item xs={8}>
      <Box sx={tablestyle} onClick={handleTableStyleClick}  color = {is_active ? "" : "gray"}>
        <Box display={"flex"} gap={2} sx={gap}>
          <LocationOnIcon sx={{ color: "#1F8FF2" }} />
          <Typography variant="h7" fontWeight={"medium"} sx={text}>
            {location_name}
          </Typography>
        </Box>
        <Box sx={numbers}>
          <Typography variant="h7" fontWeight={"medium"} sx={text}>
            {cameras_count}
          </Typography>
        </Box>
      </Box>
    </Grid>
  );
};

export default TableStyle;

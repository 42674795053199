import React, { useState, useEffect } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Box, Icon, Typography, useTheme } from "@mui/material";
import { Link } from "react-router-dom";
import { tokens } from "../../theme";
import GridView from "@mui/icons-material/GridViewRounded";
import SearchIcon from "@mui/icons-material/Search";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import logo from "../../assets/Group 35.png";
import ArrowLeftOutlinedIcon from '@mui/icons-material/ArrowLeftOutlined';
import ArrowRightOutlinedIcon from '@mui/icons-material/ArrowRightOutlined';
import { useLocation } from "react-router-dom";

// Define a functional component called 'Item' for individual menu items
const Item = ({ title, to, icon, selected, setSelected, isCollapsed }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const location = useLocation();
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  const linkStyle = {
    textDecoration: "none",
  };

  const isActive = location.pathname === to;
  return (
    <Link to={to} style={linkStyle}>
      {/* Render a MenuItem component for the menu item */}
      <MenuItem
        active={selected === title}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        style={{
          color: (isHovered || isActive) ? colors.blueAccent[500] : colors.primary[100],
          background: (isHovered || isActive) ? colors.blueAccent[800] : "transparent",
          borderRadius: (isHovered || isActive) ? "13px" : "0",
          padding: isCollapsed ? "5px" : "5px",
          height: "42px",

        }}
        onClick={() => setSelected(title)}

      >
        <Box display={"flex"} gap={"8px"} >
          <Box display={"flex"} >{(isHovered || isActive) && <hr />}</Box>
          <Icon icon={isCollapsed ? icon : icon} sx={{ color: (isHovered || isActive) ? colors.blueAccent[500] : colors.grey[700] }}>{icon}</Icon>
          {!isCollapsed && (
            <Typography fontSize={"15.57px"}>{title}</Typography>
          )}
        </Box>
      </MenuItem>
    </Link>
  );
};

// Define the 'Sidebars' component
const Sidebars = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [selected, setSelected] = useState("Dashboard");

  const generalMargin = isCollapsed ? "20px 0 15px -7px" : "20px 0 15px 13px";

  const [isLogoHovered, setIsLogoHovered] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 768) {
        setIsCollapsed(true);
      } else {
        setIsCollapsed(false);
      }
    };

    window.addEventListener("resize", handleResize);
    handleResize(); // Initial check
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);


  return (
    // Create a Sidebar component with specified properties
    <Sidebar
      collapsed={isCollapsed}
      width="208px"
      backgroundColor={colors.greenAccent[100]}
      style={{ borderColor: "transparent", }}
    >
      <Menu iconShape="square">
        {/* LOGO AND MENU ICON */}
        <MenuItem
          onMouseEnter={() => setIsLogoHovered(true)} // Handle logo hover
          onMouseLeave={() => setIsLogoHovered(false)}
          onClick={() => setIsCollapsed(!isCollapsed)}
          icon={isCollapsed ? (
            <Box sx={{ display: 'flex', alignItems: 'center' }}>

              <img src={logo} alt="" />
              <ArrowRightOutlinedIcon />
            </Box>
          ) : undefined}
          style={{
            paddingRight: isCollapsed ? "1px" : "1px",
            paddingLeft: isCollapsed ? "20px" : "1px",
            margin: "20px 0 20px 0",
            color: colors.grey[200],
            background: isLogoHovered ? colors.blueAccent[400] : "transparent",
          }}
        >
          {!isCollapsed && (
            <Box
              display="flex"
              justifyContent={"space-around"}
              alignItems="center"
            >
              <Box display={"flex"} alignItems={"center"}>
                <img
                  alt="logo"
                  src={logo}
                  style={{ borderRadius: "50%" }}
                />
              </Box>
              <Box>
                <Typography variant="h4" color={colors.blueAccent[100]}>
                  BAYWATCH
                </Typography>
                <Typography
                  fontSize={"11px"}
                  color={colors.blueAccent[200]}
                  textAlign={"center"}
                >
                  TECHNOLOGIES
                </Typography>
              </Box>

              <ArrowLeftOutlinedIcon />
            </Box>
          )}
        </MenuItem>

        <Box mt={6} mx={2} >
          <Typography
            variant="h5"
            color={colors.grey[700]}
            sx={{ m: generalMargin }}
          >
            General
          </Typography>
          {/* Render individual Item components for general menu items */}
          <Item
            title="Dashboard"
            to="/"
            icon={<GridView />}
            selected={selected}
            setSelected={setSelected}
            isCollapsed={isCollapsed}
          />
          {/* <Item
            title="Search"
            to="/Search"
            icon={<SearchIcon />}
            selected={selected}
            setSelected={setSelected}
            isCollapsed={isCollapsed}
          />
          <Item
            title="Locations"
            to="/Locations"
            icon={<LocationOnIcon />}
            selected={selected}
            setSelected={setSelected}
            isCollapsed={isCollapsed}
          /> */}
        </Box>
      </Menu>
    </Sidebar>
  );
};

export default Sidebars;

import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import client from './axiosConfig';
import { apiUrl } from './apiConfig';
import { selectSelectedBay } from './locationSlice';

const generate_dict_ips = (bayCount) => {
  const template = '192.168.10.';
  const dict = {};
  const availableCamNumbs = [1, 2, 3, 4, 5, 6];
  //for each bay create an array of camera ips that end in "bay+availalbeCamNum"
  for (let i = 1; i <= bayCount; i++) {
    dict[i] = availableCamNumbs.map((num) => template + i + num);
  }
  return dict;
}


const initialState = {
  data: [],
  error: null,
  selectedCameras: [],
  selectedButtons: [],
  selectMultiple: false,
  selectAll: false,
  loading: false,
  currentCamera: null,
  selectedIds: [],
  timeResetResults: [],
};

export const fetchCameraList = createAsyncThunk('camera/fetchCameraList', async (locationId, { dispatch }) => {
  try {
    dispatch(setLoading(true));
    const response = await client.get(`${apiUrl}locations/${locationId}/cameras/`);
    dispatch(setLoading(false));
    dispatch(setSelectAll(false));
    return response.data;
  } catch (error) {
    dispatch(setLoading(false));
    throw error;
  }
});

export const refreshCameraStatues = createAsyncThunk('camera/refreshCameraStatues', async ({locationId}) => {
  try {
    const response = await client.post(`${apiUrl}camera_status_update/`, {
      "locationId": locationId
    });
    if (response.status === 200) {
      return response.data;
    }
  } catch (error) {
    throw new Error('Error refreshing camera statuses');
  }
});
  
export const resetTimeByLocationId = createAsyncThunk('camera/resetTime', async ({id}) => {
  const response = await client.post(`${apiUrl}resetTime/`, {
      "locationId": id
  });
  if (response.status === 200) {
    return response.data;
  } else {
    throw new Error('Error resetting time');
  }

});
export const setSelectAll = createAction('camera/setSelectAll');
export const fetchCameraByIp = createAsyncThunk('camera/fetchCameraByIp', async (ip) => {
  const response = await client.get(`${apiUrl}cameras/${ip}`);
  return response.data;
});

export const setLoading = createAction('camera/setLoading');

const cameraSlice = createSlice({
  name: 'camera',
  initialState,
  reducers: {
    setCurrentCamera: (state, action) => {
      state.currentCamera = action.payload;
    },
    buttonClick: (state, action) => {
      const buttonNumber = action.payload;
      if (state.selectMultiple) {
      if (state.selectedButtons.includes(buttonNumber)) {
        state.selectedButtons = state.selectedButtons.filter((num) => num !== buttonNumber);
      } else {
        state.selectedButtons.push(buttonNumber);
      }
      //sort the state.selectedButtons array in ascending order
      state.selectedButtons.sort((a, b) => a - b);
      } else {
      if (state.selectAll) {
        state.selectedButtons = state.selectedButtons.filter((num) => num !== buttonNumber);
      } else {
        state.selectedButtons = [buttonNumber];
      }
      }
      state.selectedCameras = state.selectedButtons.map((buttonNumber) => {
      const cameraData = state.data.find((camera) => camera.camera_ip.slice(-2) === buttonNumber.toString());
      return { ...cameraData, buttonNumber }; // Spread the cameraData properties
      });
      state.selectedIds = state.selectedCameras.map((camera) => camera.id);
      state.currentCamera = state.selectedCameras.length > 0 ? state.selectedCameras[0] : null;
    },
    selectAll: (state) => {
      state.selectAll = !state.selectAll;
      state.selectMultiple = false;
      // if selectAll is true, then set state.selectedButtons to all buttons
      if (state.selectAll) {
        state.selectedCameras = [];
        state.selectedIds = [];
        state.selectedButtons = state.data.map((camera) => camera.camera_ip.slice(-2));
        state.selectedButtons.sort((a, b) => a - b);
        state.selectedCameras = state.selectedButtons.map((buttonNumber) => {
          const cameraData = state.data.find((camera) => camera.camera_ip.slice(-2) === buttonNumber.toString());
          return { ...cameraData, buttonNumber }; // Spread the cameraData properties
        });
        state.selectedIds = state.selectedCameras.map((camera) => camera.id);
        state.currentCamera = state.selectedCameras.length > 0 ? state.selectedCameras[0] : null;
      } else {
        state.selectedButtons = [];
        state.selectedCameras = [];
        state.selectedIds = [];
        state.currentCamera = null;
      }
    },    
    selectMultiple: (state) => {
      // Toggle selectMultiple
      state.selectMultiple = !state.selectMultiple;
    
      // If selectMultiple is turned false, clear selectedButtons
      if (!state.selectMultiple) {
        state.selectedButtons = [];
      }
    
      // Ensure selectAll is false when selectMultiple is active
      state.selectAll = false;
    },
    
    // create an action to move the 'currentCamera' to the next camera in the 'selectedCamera array'. If the current camera is at the end of the array, do nothing.
    nextCamera: (state) => {
      if (state.currentCamera != null) {
        const index = state.selectedIds.indexOf(state.currentCamera.id);
        if (index < state.selectedIds.length - 1) {
          return {
            ...state,
            currentCamera: state.selectedCameras[index + 1],
          };
        }
      }
      return state;
    },
    
    // create an action to move the 'currentCamera' to the previous camera in the 'selectedCamera array'. If the current camera is at the beginning of the array, do nothing.
    previousCamera: (state) => {
      if (state.currentCamera != null) {
        const index = state.selectedIds.indexOf(state.currentCamera.id);
        if (index > 0) {
          return {
            ...state,
            currentCamera: state.selectedCameras[index - 1],
          };
        }
      }
      return state;
    },
    clearSelectedCamerasButtons: (state) => {
      state.selectedCameras = [];
      state.currentCamera = null;
      state.selectedButtons = [];
      state.selectedIds = [];
    },
    setSelectedIdsByBay: (state, action) => {
      const selectedBay = action.payload;
      const data = state.data;
      const ips = generate_dict_ips(selectedBay)
      //for each camera ip in the ips[selectedBay] array, find the camera in the state.data array that has the same camera_ip and add the id to the state.selectedIds array
      
      const new_ids = ips[selectedBay].map((ip) => state.data.find((camera) => camera.camera_ip === ip).id);
      state.selectedIds = new_ids;
    }
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCameraList.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchCameraByIp.pending, (state) => {
        state.error = null;
      })
      .addCase(fetchCameraList.fulfilled, (state, action) => {
        state.data = action.payload;

      })
      .addCase(fetchCameraByIp.fulfilled, (state, action) => {
        state.data = action.payload;
      })
      .addCase(fetchCameraList.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(fetchCameraByIp.rejected, (state, action) => {
        state.error = action.error.message;
      })
      .addCase(setLoading, (state, action) => {
        state.loading = action.payload;
      })
      .addCase(setSelectAll, (state, action) => {
        state.selectAll = action.payload;
      })
      .addCase(resetTimeByLocationId.fulfilled, (state, action) => {
        state.timeResetResults = action.payload;
      })
      //cases for the refreshCameraStatues action
      .addCase(refreshCameraStatues.pending, (state) => {
        state.error = null;
        state.loading = true;
      })
      .addCase(refreshCameraStatues.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(refreshCameraStatues.rejected, (state, action) => {
        state.error = action.error.message;
        state.loading = false;
      });

  },
});

export const {
  buttonClick,
  selectAll,
  selectMultiple,
  setCurrentCamera, nextCamera, previousCamera, clearSelectedCamerasButtons: clearSelectedCamerasButtons, setSelectedIdsByBay
} = cameraSlice.actions;


export const selectCurrentCamera = (state) => state.camera.currentCamera;
export const selectSelectedCameras = (state) => state.camera.selectedCameras;
export const selectSelectedButtons = (state) => state.camera.selectedButtons;
export const selectSelectAll = (state) => state.camera.selectAll;
export const selectSelectMultiple = (state) => state.camera.selectMultiple;
export const selectCurrentCameraIp = (state) => state.camera.currentCamera?.camera_ip.slice(-2);
export const selectCurrentCameraId = (state) => state.camera.currentCamera?.id;
export const selectSelectedIds = (state) => state.camera.selectedIds;
export const selectCameras = (state) => state.camera.data;
export const selectLoading = (state) => state.camera.loading;

export const selectInactiveCameraIds = (state) => state.camera.data.filter((camera) => camera.active === false).map((camera) => camera.id);

export default cameraSlice.reducer;

import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import client from './axiosConfig';
import { apiUrl } from './apiConfig'
import { selectCurrentCameraIp } from './cameraSlice';


/*

Example of reponse from backend from startStream and stopStream:

    return Response({
                    'message': 'Stream manager executed correctly on Siletz Backend. Check data for more info',
                    'updated_camera_streams': updated_camera_streams_data
                }, status=status.HTTP_200_OK)

Each updated_camera_streams_data is a dictionary with the following keys

        "camera_id": camera_stream_object.camera.camera_id,
        "camera_ip": camera_stream_object.camera.camera_ip,
        "destination_dir": camera_stream_object.destination_dir,
        "pid": camera_stream_object.pid,
        "status": camera_stream_object.status,
        "url": camera_stream_object.url,

*/


/*

example of current streams list:

currentStreams: [
      {
        camera_ip: '192.168.10.12',
        url: 'http://100.74.130.2/hls/cam2/stream.m3u8'
      }
    ]

*/


const initialState = {
    data: [],
    error: null,
    loading: false,
    showLiveFeed: false,
    currentStreams: [],
};


export const startStream = createAsyncThunk('stream/startStream', async ({locationId, cameraIds}, { getState }) => {
    const response = await client.post(`${apiUrl}start_stream/`, {
        locationId: locationId,
        cameraIds: cameraIds
    });
    if (response.status === 200 || response.status === 206) {
        // Extract the updated camera streams data from the response
        const updatedCameraStreamsData = response.data.updated_camera_streams;

        // Extract the existing current streams from the state
        const { currentStreams } = getState().stream;

        // Map the updated camera streams data to the format you want
        const newStreams = updatedCameraStreamsData.map(stream => ({
            camera_id: stream.camera_id,
            camera_ip: stream.camera_ip,
            url: stream.url,
            status: stream.status,
            camera_number: stream.camera_ip.slice(-1)
        }));

        // Append the new streams to the current streams
        const updatedStreams = [...currentStreams, ...newStreams];

        // Return both response data and updated streams
        return { responseData: response.data, updatedStreams };
    } else {
        throw new Error('Stream(s) could not be started');
    }
});

export const stopStream = createAsyncThunk('stream/stopStream', async ({locationId, cameraIds}, { getState }) => {
    const response = await client.post(`${apiUrl}stop_stream/`, {
        locationId: locationId,
        cameraIds: cameraIds
    });
    if (response.status === 200 || response.status === 206) {
        // Extract the camera IDs that were stopped
        const stoppedCameraIds = cameraIds;

        // Extract the existing current streams from the state
        const { currentStreams } = getState().stream;

        // Filter out the streams corresponding to the stopped cameras
        const updatedStreams = currentStreams.filter(stream => !stoppedCameraIds.includes(stream.camera_id));
        // Return both response data and updated streams
        return { responseData: response.data, updatedStreams };
    } else {
        throw new Error('Stream(s) could not be stopped');
    }
});



const streamSlice = createSlice({
    name: "stream",
    initialState,
    reducers: {
        toggleLiveFeed: (state) => {
            state.showLiveFeed = !state.showLiveFeed;
        },
        setLiveFeed: (state, action) => {
            state.showLiveFeed = action.payload;
        }
    },
    extraReducers: (builder) => {
        builder

            .addCase(startStream.pending, (state) => {
                state.loading = true;
            })
            .addCase(stopStream.pending, (state) => {
                state.loading = true;
            })
            .addCase(startStream.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
            .addCase(stopStream.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
            })
            .addCase(startStream.fulfilled, (state, action) => {
                // Update currentStreams with the new streams
                state.currentStreams = action.payload.updatedStreams;
                state.loading = false;
            })
            .addCase(stopStream.fulfilled, (state, action) => {
                // Update currentStreams by removing the stopped streams
                state.currentStreams = action.payload.updatedStreams;
                state.loading = false;
            });
    }
});



export const { toggleLiveFeed, setLiveFeed} = streamSlice.actions;

export const selectShowLiveFeed = (state) => state.stream.showLiveFeed;
export const selectCurrentStreamList = (state) => state.stream.currentStreams;
export const selectCurrentStreamUrl = (state) => {
    const currentCameraIp = selectCurrentCameraIp(state);
    const currentStream = state.stream.currentStreams.find(stream => stream.camera_ip.slice(-2) === currentCameraIp);
    return currentStream ? currentStream.url : null;
};
export const selectLoading = (state) => state.stream.loading;
export default streamSlice.reducer;

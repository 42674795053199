import React from "react";
import { Box, Grid, Typography } from "@mui/material";
import { tokens } from "../../theme";
import { useTheme } from "@mui/material";


const TableHeading = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const tableheading = {
    display: "flex",
    justifyContent: "space-between",
    padding: "10px",
    px: "30px",
    "@media (max-width: 940px)": {
      px: "22px",
    }

  }
  const tablestyle = {
    display: "flex",
    justifyContent: "space-between",
    padding: "20px",
    px: "30px",
    "&:hover, &:active": {

      cursor: "pointer",
    },
    "@media (max-width: 1080px)": {
      px: "20px",
    },
    "@media (max-width: 940px)": {
      px: "10px",
    }

  }
  const numbers = {
    color: colors.blueAccent[300],
    backgroundColor: colors.blueAccent[800],
    border: "1px solid",
    borderColor: colors.blueAccent[700],
    p: "5px",
    px: "25px",
    borderRadius: "80px",
    "@media (max-width: 1080px)": {
      p: "1px",
      px: "12px",
    },
    "@media (max-width: 940px)": {
      px: "5px",
    },
    "@media (max-width: 600px)": {
      my: "5px",
    }
  }
  const text = {
    "@media (max-width: 940px)": {
      fontSize: "12px"
    }
  }
  const gap = {
    "@media (max-width: 940px)": {
      gap: "2px",
    }
  }

  return (
    <Box p={"5px"} sx={{ backgroundColor: colors.blueAccent[600] }}>
      <Grid container spacing={2} columns={{ xs: 8, sm: 16, md: 16 }}>
        <Grid item xs={8}>
          <Box sx={tableheading}>
            <Typography variant="h7" fontWeight={"medium"} sx={text}>
              Location
            </Typography>
            <Typography variant="h7" fontWeight={"medium"} sx={text}>
              Cameras
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={8}>
          <Box sx={tableheading}>
            <Typography variant="h7" fontWeight={"medium"} sx={text}>
              Location
            </Typography>
            <Typography variant="h7" fontWeight={"medium"} sx={text}>
              Cameras
            </Typography>
          </Box>
        </Grid>
        {/* Add another Grid item for responsive design if needed */}
      </Grid>
    </Box>
  );
};

export default TableHeading;

import { useContext } from "react";
import { IconButton, Typography, Box, useTheme } from "@mui/material";
import { ColorModeContext, tokens } from "../theme";
import CircleRoundedIcon from '@mui/icons-material/CircleRounded';
import DarkModeRoundedIcon from '@mui/icons-material/DarkModeRounded';
import ExitToAppRoundedIcon from "@mui/icons-material/ExitToAppRounded";
import profileLogo from "../assets/genericProfile.png";
import { useSelector} from "react-redux";
import { selectFirstName, selectLastName, selectEmail } from "../redux/slices/userSlice";


const Header = ({ title,handleLogout }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const colorMode = useContext(ColorModeContext);
  const name = useSelector(selectFirstName) + " " + useSelector(selectLastName);

  return (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      {/* Header Title */}
      <Typography
        variant="h3"
        color={colors.grey[300]}
        fontWeight="medium"
        sx={{"@media (max-width: 1000px)": { fontSize: "18px" }}}
      >
        {title}
      </Typography>

      {/* Icons and user info */}
      <Box display="flex" gap={2} >
        {/* Color mode toggle button */}
        <IconButton onClick={colorMode.toggleColorMode} sx={{border: "0.5px solid" , borderColor: colors.greenAccent[500]}}>
          {theme.palette.mode === "dark" ? (
            <CircleRoundedIcon sx={{color: "#F5CE42"}}/>
          ) : (
            <DarkModeRoundedIcon sx={{color: "#262835"}}/>
          )}
        </IconButton>
        <hr color={colors.greenAccent[500]}/>
        <Box display={{ xs: "none", sm: "block" }}>
          <IconButton sx={{ p: [0], xs: "none", sm: "block" }}>
            <img alt="" src={profileLogo} />
          </IconButton>
        </Box>
        
        <Box display={{ xs: "none", sm: "block" }}>
          <Typography fontSize={13} color={colors.grey[300]} sx={{ m: "0" }}>
            {name}
          </Typography>
          <Typography fontSize={12} color={colors.grey[500]}>
            {useSelector(selectEmail)}
          </Typography>
        </Box>
        <hr color={colors.greenAccent[500]} />
        {/* Logout button */}
        <IconButton
          onClick={handleLogout}
          sx={{
            border: "1px solid",
            borderRadius: "120px",
            borderColor: colors.greenAccent[500],
            px: "10px",
            gap: [1],
            alignItems: "center",
            "@media (max-width: 600px)": {
              "& .MuiIconButton-label": {
                display: "none",
              },
            },
          }}
        >
          <ExitToAppRoundedIcon sx={{color: colors.primary[100]}}/>
          <Typography
            color= {colors.primary[100]}
            fontSize={"13.49px"}
            sx={{ "@media (max-width: 1030px)": { display: "none" }}}
          >
            Log Out
          </Typography>
        </IconButton>
      </Box>
    </Box>
  );
};

export default Header;

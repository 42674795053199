import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
// Import BrowserRouter for routing
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./redux/store";

// Create a React root to render the application
const root = ReactDOM.createRoot(document.getElementById("root"));

// Render the application inside a BrowserRouter and wrap it in React.StrictMode
root.render(
  // Wrap App in Provider
  <Provider store={store}>
    <React.StrictMode>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </React.StrictMode>
  </Provider>
);

// rootReducer.js
import { combineReducers } from 'redux';
import location from '../slices/locationSlice';
import camera from '../slices/cameraSlice';
import controller from '../slices/controllerSlice';
import user from '../slices/userSlice';
import stream from '../slices/streamSlice';
import general from '../slices/generalSlice';
import notification from '../slices/notificationSlice';

const rootReducer = combineReducers({
  location,
  camera,
  controller,
  user,
  stream,
  general,
  notification
});

export default rootReducer;

import { Typography, MenuItem, Menu, Box } from "@mui/material";
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import { useSelector } from 'react-redux';
import { selectSpecificSettingValue } from "../../redux/slices/controllerSlice";

const DropDownBox = ({
  title,
  anchorEl,
  setAnchorEl,
  handleDropdownClick,
  handleDropdownClose,
  options,
  handleChange,
  nickname,
}) => {

  const handleOptionClick = (option) => {
    handleDropdownClose(setAnchorEl);
    handleChange(null, option);
  };

  return (
    <Box width="100%" display="flex" flexDirection="column" gap="10px">
      <Typography variant="h6">{title}</Typography>
      <Box
        display="flex"
        justifyContent="space-between"
        padding="10px"
        border={1}
        borderRadius="10px"
        borderColor="#585858"
      >
        <Typography>{useSelector((state) => selectSpecificSettingValue(state, nickname))}</Typography>
        <KeyboardArrowDownRoundedIcon
          onClick={(event) => handleDropdownClick(event, setAnchorEl)}
        />
      </Box>
      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={() => handleDropdownClose(setAnchorEl)}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        {options.map((option) => (
          <MenuItem
            key={option}
            onClick={() => handleOptionClick(option)}
          >
            {option}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  );
};

export default DropDownBox;
import { createSlice, createAsyncThunk, createAction } from '@reduxjs/toolkit';
import client from './axiosConfig';
import { apiUrl } from './apiConfig';
import { resetTimeByLocationId } from './cameraSlice';


const initialState = {
  data: [],
  error: null,
  loading: false,
  controllerView: 'general',
  CamerasWithSettings: [],
  displayedSettings: [],
  isDay: true,
  gainAuto: true,
  modifiedSettings: {},
  refreshingCameras: false,
};

export const refreshCameras = createAsyncThunk(
  'controller/refreshCameras',
  async ({ camera_ids, location_id }, { rejectWithValue }) => {
    try {
      const response = await client.post(`${apiUrl}refresh/`, {
        cameraIds: camera_ids,
        locationId: location_id,
        
      });

      if (!response.status === 200) {
        throw new Error('Network response was not ok');
      }

      // Handle the response data here if needed
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const defaultCameras = createAsyncThunk(
  'controller/defaultCameras',
  async ({ locationId, cameraIds }, { rejectWithValue }) => {
    try {
      const response = await client.post(`${apiUrl}defaultSetting/`, {
        cameraIds: cameraIds,
        locationId: locationId,
      });

      if (!response.status === 200) {
        throw new Error('Network response was not ok');
      }

      // Handle the response data here if needed
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchAllSettingsByCameraId = createAsyncThunk(
  'camera/fetchAllSettingsByCameraId',
  async (cameraId, { rejectWithValue }) => {
    try {
      const response = await client.get(`${apiUrl}cameras/${cameraId}/all_settings/`);
      return response.data;
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);
export const postSettingByCameraList = createAsyncThunk(
  'camera/updateSetting',
  async ({ payload }, { rejectWithValue, dispatch }) => {
    try {
      const response = await client.post(`${apiUrl}changeSetting/`, {
        cameraIds: payload.cameraIds,
        locationId: payload.locationId,
        settings: payload.settings,
      });

      if (!response.status === 200) {
        throw new Error('Network response was not ok');
      }
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const fetchSettingsForSelectedCameras = createAsyncThunk(
  'camera/fetchSettingsForSelectedCameras',
  async (selectedCameras, { dispatch, rejectWithValue }) => {
    try {
      await Promise.all(
        selectedCameras.map(async (camera) => {
          try {
            await dispatch(fetchAllSettingsByCameraId(camera.id));
          } catch (error) {
            // Handle individual camera fetch error within the loop
            return rejectWithValue(error.message);
          }
        })
      );
    } catch (error) {
      // Handle error occurred during fetching settings for selected cameras
      return rejectWithValue(error.message);
    }
  }
);

export const setLoading = createAction('camera/setLoading');
export const setError = createAction('camera/setError');
export const handleFetchSettingsError = createAction('camera/handleFetchSettingsError');
const controllerSlice = createSlice({
  name: 'controller',
  initialState,
  reducers: {
    setControllerView: (state, action) => {
      state.controllerView = action.payload;
    },
    setGainAuto: (state, action) => {
      state.gainAuto = action.payload;
    },
    resetState: (state) => {
      Object.assign(state, initialState);
    },
    clearModifiedSettings: (state) => {
      state.modifiedSettings = {};
    },
    toggleDay: (state) => {
      state.isDay = !state.isDay;
    },
    sortData: (state, action) => {
      const { camera_settings, common_settings, profile_settings } = action.payload;
      const camera_ip = common_settings[0].camera_ip;

      const generalSettings = common_settings.map((setting) => ({
        id: setting.id,
        setting_name: setting.setting_name,
        setting_value: setting.setting_value,
      }));

      const daySettings = [];
      const nightSettings = [];
      profile_settings.forEach((setting) => {
        const settingObj = {
          id: setting.id,
          setting_name: setting.setting_name,
          setting_value: setting.setting_value,
        };

        setting.profile_name === 'Day' ? daySettings.push(settingObj) : nightSettings.push(settingObj);
      });

      const existingCameraIndex = state.CamerasWithSettings.findIndex((camera) => camera.camera_id === action.meta.arg);

      if (existingCameraIndex !== -1) {
        state.CamerasWithSettings[existingCameraIndex] = {
          camera_id: action.meta.arg,
          camera_ip,
          generalSettings,
          daySettings,
          nightSettings,
        };
      } else {
        state.CamerasWithSettings.push({
          camera_id: action.meta.arg,
          camera_ip,
          generalSettings,
          daySettings,
          nightSettings,
        });
      }
    },
    handleFetchSettingsError: (state, action) => {
      state.error = action.payload;
    },
    setInitialDisplayedSettings: (state, { payload: currentCamera }) => {
      const camerasWithSettings = state.CamerasWithSettings;
      const matchingCamera = camerasWithSettings.find((camera) => camera.camera_id === currentCamera?.id);
    
      if (matchingCamera) {
        return { ...state, displayedSettings: matchingCamera };
      } else {
        return { ...state, displayedSettings: [] };
      }
    },
    
    changeSettingValue: (state, action) => {
      const { title: setting_name, value: setting_value } = action.payload;

      const findAndModify = (settingsArray) => {
        const index = settingsArray.findIndex(setting => setting.setting_name.includes(setting_name));
        if (index !== -1) {
          settingsArray[index].setting_value = setting_value;
        } else {
        }
      };    

      switch(state.controllerView){
        case 'general':
          findAndModify(state.displayedSettings.generalSettings);
          break;
        case 'video': case 'color':
          if (state.isDay) {
            findAndModify(state.displayedSettings.daySettings);
          } else {
            findAndModify(state.displayedSettings.nightSettings);
          }
          break;
      }
    },
    clearSettings: (state) => {
      state.displayedSettings = [];
      state.modifiedSettings = {};
    },

    findModifiedSettings : (state, action) => {

      // find a camera with matching camera_id in state.CamerasWithSettings
      const camera = action.payload;
      const matchingCamera = state.CamerasWithSettings.find((cameraWithSettings) => cameraWithSettings.camera_id === camera.id);

      // create empty array for settings

      const modifiedSettings = {};

      // check if matchingCamera exists

      if (matchingCamera) {

        // loop through settings and compare to state.displayedSettings.generalSettings, daySettings, and nightSettings with matchingCamera.generalSettings, daySettings, and nightSettings. If the setting_value is different, add the setting_name and setting_value to modifiedSettings
        let tempSetting;
        matchingCamera.generalSettings.forEach((setting) => {
          tempSetting = state.displayedSettings.generalSettings.find((displayedSetting) => displayedSetting.setting_name === setting.setting_name)
          if (setting.setting_value !== tempSetting?.setting_value) {
            modifiedSettings[tempSetting?.setting_name] = tempSetting?.setting_value;
          }
        });
        
        matchingCamera.daySettings.forEach((setting) => {
          tempSetting = state.displayedSettings.daySettings.find((displayedSetting) => displayedSetting.setting_name === setting.setting_name)
          if (setting.setting_value !== tempSetting?.setting_value) {
            modifiedSettings[tempSetting?.setting_name] = tempSetting?.setting_value;
          }
        });

        matchingCamera.nightSettings.forEach((setting) => {
          tempSetting = state.displayedSettings.nightSettings.find((displayedSetting) => displayedSetting.setting_name === setting.setting_name)
          if (setting.setting_value !== tempSetting?.setting_value) {
            modifiedSettings[tempSetting?.setting_name] = tempSetting?.setting_value;
          }
        })

        state.modifiedSettings = modifiedSettings;
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSettingsByCameraId.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchAllSettingsByCameraId.fulfilled, (state, action) => {
        state.loading = false;
        state.data = action.payload;
        controllerSlice.caseReducers.sortData(state, action);
      })
      .addCase(fetchAllSettingsByCameraId.rejected, (state, action) => {
        state.loading = false;
        // Handle or log the error at the slice level
        state.error = action.payload.message;
      })
      .addCase(fetchSettingsForSelectedCameras.pending, (state) => {
        state.loading = true;
      })
      .addCase(fetchSettingsForSelectedCameras.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(fetchSettingsForSelectedCameras.rejected, (state, action) => {
        state.loading = false;
        // Handle or log the error at the slice level
        state.error = action.payload.message;
      })
      .addCase(setLoading, (state, action) => {
        state.loading = action.payload;
      })
      .addCase(setError, (state, action) => {
        const { camera_id, error  } = action.payload;
        camera_id == null ? state.error = "An error occurred during refresh or another process: " + error : state.error = "An error occurred on camera " + camera_id + ": " + error;
      })
      .addCase(refreshCameras.fulfilled, (state) => {
        state.loading = false;
        state.refreshingCameras = false;
      })
      .addCase(refreshCameras.rejected, (state, action) => {
        state.loading = false;
        state.refreshingCameras = false;
        // Handle or log the error at the slice level
        state.error = action.payload.message;
      })
      .addCase(refreshCameras.pending, (state) => {
        state.loading = true;
        state.refreshingCameras = true;
      })
      .addCase(postSettingByCameraList.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(postSettingByCameraList.rejected, (state, action) => {
        state.loading = false;
        // Handle or log the error at the slice level
        state.error = action.payload.message;
      })
      .addCase(postSettingByCameraList.pending, (state) => {
        state.loading = true;
      })
      .addCase(resetTimeByLocationId.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(resetTimeByLocationId.rejected, (state, action) => {
        state.loading = false;
        // Handle or log the error at the slice level
        state.error = action.payload.message;
        window.alert("Could not reset time. Please contact suppport.");
      })
      .addCase(resetTimeByLocationId.pending, (state) => {
        state.loading = true;
      })
      .addCase(defaultCameras.pending, (state) => {
        state.loading = true;
      })
      .addCase(defaultCameras.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(defaultCameras.rejected, (state, action) => {
        state.loading = false;
        // Handle or log the error at the slice level
        state.error = action.payload.message;
        window.alert("Could not default cameras. Please contact suppport.");
      })
  },
});

export const { setControllerView: setControllerView, resetState, setInitialDisplayedSettings: setInitialDisplayedSettings, changeSettingValue, toggleDay, setGainAuto, clearSettings, findModifiedSettings, clearModifiedSettings } = controllerSlice.actions;

export const selectIsDay = (state) => state.controller.isDay;
export const selectControllerView = (state) => state.controller.controllerView;
export const selectData = (state) => state.controller.data;
export const selectLoading = (state) => state.controller.loading;
export const selectError = (state) => state.controller.error;
export const selectSettings = (state) => state.controller.CamerasWithSettings;
export const selectDisplayedSettings = (state) => state.controller.displayedSettings;
export const selectGainAuto = (state) => state.controller.gainAuto;
export const selectModifiedSettings = (state) => state.controller.modifiedSettings;
export const selectSuccessfullPost = (state) => state.controller.successfulPost;
export const selectRefreshingCameras = (state) => state.controller.refreshingCameras;


export const translateSubResolution = (subResolution) => {
  switch(subResolution) {
    case '352*240(CIF)':
      subResolution = 'CIF'
      break;
    case '640*480(VGA)':
      subResolution = 'VGA'
      break;
    case '704*480(D1)':
      subResolution = 'D1'
      break;

    // opposite cases
    case 'CIF':
      subResolution = '352*240(CIF)'
      break;
    case 'VGA':
      subResolution = '640*480(VGA)'
      break;
    case 'D1':
      subResolution = '704*480(D1)'
      break;

    // default
    default:
      subResolution = 'No Data Available'
  }
  
  return subResolution
}
export const translateExposure = (value) => {
  switch (value) {
    case '333.333':
      return '1/3';
    case '250':
      return '1/4';
    case '200':
      return '1/5';
    case '166.666':
      return '1/6';
    case '8':
      return '1/120';
    case '4':
      return '1/250';
    case '2':
      return '1/500';
    case '1':
      return '1/1000';
    case '0':
      return '1/2000';
    case '1/3':
      return '333.333';
    case '1/4':
      return '250';
    case '1/5':
      return '200';
    case '1/6':
      return '166.666';
    case '1/120':
      return '8';
    case '1/250':
      return '4';
    case '1/500':
      return '2';
    case '1/1000':
      return '1';
    case '1/2000':
      return '0';
    default:
      return 'No Data Available';
  }
};

export const translateAntiFlicker = (value) => {
  switch (value) {
    case '0':
      return 'Auto';
    case '1':
      return 'Outdoor';
    case '2':
      return '50hz';
    case '3':
      return '60hz';
    case 'Auto':
      return '0';
    case 'Outdoor':
      return '1';
    case '50hz':
      return '2';
    case '60hz':
      return '3';
    default:
      return 'No Data Available';
  }
};

export const translateColorMode = (value) => {
  switch (value) {
    case '0':
      return 'Color';
    case '2':
      return 'B&W';
    case '1':
      return 'Auto';
    case 'Color':
      return '0';
    case 'B&W':
      return '2';
    case 'Auto':
      return '1';
    default:
      return 'No Data Available';
  }
};


export const selectSpecificSettingValue = (state, settingName) => {
  const settings = selectDisplayedSettings(state);
  const isDay = selectIsDay(state);
  const view = selectControllerView(state);

  if (settings) {
    if (view === 'general') {
      const generalSetting = settings.generalSettings?.find((setting) => setting.setting_name.includes(settingName));
      
      if (generalSetting) {
        if (settingName === 'Time') {
          return generalSetting.setting_value.split(' ')[1];
        }
        if (settingName === 'ExtraFormat[0].Video.CustomResolutionName') {
          return translateSubResolution(generalSetting.setting_value);
        }
        return generalSetting.setting_value;
      }
    } else if (isDay) {
      const daySetting = settings.daySettings?.find((setting) => setting.setting_name.includes(settingName));
      if (settingName === 'ExposureMode'){
        if (daySetting?.setting_value === '0'){
          return 'Auto';
        } else {
          const daySetting = settings.daySettings?.find((setting) => setting.setting_name.includes('ExposureValue'));
          return translateExposure(daySetting?.setting_value);
        }
      } else if ( settingName === 'AntiFlicker') {
        return translateAntiFlicker(daySetting?.setting_value);
      } else if (settingName === 'DayNightColor') {
        return translateColorMode(daySetting?.setting_value);
      }
      return daySetting?.setting_value;
    } else if (!isDay) {
      const nightSetting = settings.nightSettings?.find((setting) => setting.setting_name.includes(settingName));
      if (settingName === 'ExposureMode'){
        if (nightSetting?.setting_value === '0'){
          return 'Auto';
        } else {
          const nightSetting = settings.nightSettings?.find((setting) => setting.setting_name.includes('ExposureValue'));
          return translateExposure(nightSetting?.setting_value);
        }
      } else if ( settingName === 'AntiFlicker') {
        return translateAntiFlicker(nightSetting?.setting_value);
      } else if (settingName === 'DayNightColor') {
        return translateColorMode(nightSetting?.setting_value);
      }
      return nightSetting?.setting_value;
    }
  }

  return "No Data Available";
};

export default controllerSlice.reducer;




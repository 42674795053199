import React from 'react';
import { Box, Button, useTheme, Typography } from "@mui/material";
import { tokens } from "../../theme";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { useSelector, useDispatch } from 'react-redux';
import { selectSelectedLocationName} from '../../redux/slices/locationSlice';
import {selectSelectedCameras, nextCamera, previousCamera, selectCurrentCamera } from '../../redux/slices/cameraSlice';

const ControllerHeader = ({stopStreamOnNextPrevClick}) => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const currentLocationName = useSelector(selectSelectedLocationName);
    const selectedCameras = useSelector(selectSelectedCameras);
    const dispatch = useDispatch();
    const currentCamera = useSelector(selectCurrentCamera)

    const handleNextClick = () => {
      dispatch(nextCamera());
      stopStreamOnNextPrevClick();

    }
    const handlePreviousClick = () => {
      dispatch(previousCamera());
      stopStreamOnNextPrevClick();
    }
  
    return (
      <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems="center"
        p={{ xs: "10px", sm: "20px" }}
        sx={{
          backgroundColor: colors.grey[900],
          borderTopLeftRadius: [20],
          borderTopRightRadius: [20],
          borderBottom: "1px solid #585858"
        }}
      >
        <Box display="flex" gap="10px">
          <LocationOnIcon sx={{ color: "#1F8FF2" }} />
          <Typography variant="h4" fontWeight="medium" sx={{ color: colors.grey[100], "@media (max-width: 1080px)": { fontSize: "14px" } }}>
            {currentLocationName}
          </Typography>
        </Box>
  
        <Box display="flex" gap="35px" sx={{ "@media (max-width: 1080px)": { gap: "15px", my: "10px" } }}>
  
          { selectedCameras.length > 1 && (
          <Box display="flex" gap="12px">
            {currentCamera !== selectedCameras[0] && (
              <Button
                sx={{
                  textTransform: "none",
                  backgroundColor: "transparent",
                  borderRadius: "15px",
                  width: "125px",
                  border: "1px solid transparent",
                  borderColor: colors.blueAccent[100],
                  "@media (max-width: 940px)": {
                    width: "85px",
                  },
                  "@media (max-width: 600px)": {
                    width: "10px",
                    
                  }
                }}
                onClick={handlePreviousClick}
              >
                <Typography color={colors.blueAccent[100]}>Previous</Typography>
              </Button>
            )}
            {currentCamera !== selectedCameras[selectedCameras.length - 1] && (
              <Button
                sx={{
                  textTransform: "none",
                  width: "125px",
                  backgroundColor: "transparent",
                  borderRadius: "15px",
                  border: "1px solid transparent",
                  borderColor: colors.blueAccent[100],
                  "@media (max-width: 940px)": {
                    width: "85px",
                  },
                  "@media (max-width: 600px)": {
                    width: "10px",
                  }
                }}
                onClick={handleNextClick}
              >
                <Typography color={colors.blueAccent[100]}>Next</Typography>
              </Button>
            )}
          </Box>
          )}
        </Box>
      </Box>
    )
  }
  
  export default ControllerHeader
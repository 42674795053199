import { Box, Button, useTheme, Typography } from "@mui/material";
import { tokens } from "../../theme";
import {  buttonClick, selectSelectedButtons, selectSelectAll, selectCameras, selectLoading } from '../../redux/slices/cameraSlice';
import { useSelector, useDispatch } from 'react-redux';
import LoadingIcon from '../Loading';

const Camera = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const isSelectAll = useSelector(selectSelectAll);
    const selectedButtons = useSelector(selectSelectedButtons);
    const dispatch = useDispatch();
    const cameras = useSelector(selectCameras);
    const loading = useSelector(selectLoading);
    const numbers = (buttonNumber, active) => ({
        backgroundColor: active ? colors.blueAccent[800] : colors.grey[400],
        border: (isSelectAll || selectedButtons.includes(buttonNumber)) ? "1px solid" : "1px solid transparent",
        borderColor: (isSelectAll || selectedButtons.includes(buttonNumber)) ? colors.blueAccent[700] : "transparent",
        p: "50px 68px",
        borderRadius: "10px",
        textTransform: "none",
        "&:hover": {
          backgroundColor: colors.blueAccent[800],
          border: "1px solid",
          borderColor: colors.blueAccent[700],
        },
        "@media (max-width: 1450px)": {
          p: "35px 46px",
        },
        "@media (max-width: 1080px)": {
          p: "28px 36px",
        },
        "@media (max-width: 940px)": {
          p: "22px 26px",
        },
        "@media (max-width: 600px)": {
          p: "16px 16px",
        }
    });

    const handleButtonClick = (buttonNumber) => {
        dispatch(buttonClick(buttonNumber));
    };

    const buttonsPerRow = 6;
    const rowsNeeded = Math.ceil(cameras.length / buttonsPerRow);

    if (loading) {
      return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
          <LoadingIcon color={colors.blueAccent[100]} />
        </div>
      );
    }

    return (
        <Box p={"20px"} display={"flex"} flexDirection={"column"} gap={"22px"}
          sx={{
            "@media (max-width: 1080px)": { p: "10px", gap: "12px" },
            "@media (max-width: 600px)": { flexDirection: "row", gap: "5px", p: "5px", }
          }}
        >
          {[...Array(rowsNeeded)].map((_, rowIndex) => (
            <Box display={"flex"} justifyContent={"space-between"} sx={{ "@media (max-width: 600px)": { display: "flex", flexDirection: "column", gap: "5px" } }}>
              {cameras
                .slice(rowIndex * buttonsPerRow, (rowIndex + 1) * buttonsPerRow)
                .map((camera) => {
                  const buttonNumber = Number(camera.camera_ip.slice(-2));
  
                  return (
                    <Button
                      key={buttonNumber}
                      sx={numbers(buttonNumber, camera.active)}
                      onClick={() => handleButtonClick(buttonNumber)}
                    >
                      <Typography variant="h4" color={colors.blueAccent[100]}>
                        {camera.camera_ip.slice(-2)}
                      </Typography>
                    </Button>
                  );
                })}
            </Box>
          ))}
        </Box>
      ); 
}

export default Camera
        
import React, { useState } from "react";
import { Box, Button, Grid, Typography } from "@mui/material";
import { tokens } from "../../theme";
import TableStyle from "./TableStyle";
import { useTheme } from "@mui/material";
import { useDispatch } from "react-redux";
import { refreshLocationList} from "../../redux/slices/locationSlice";
import LoadingIcon from '../Loading';
import { setNotification } from "../../redux/slices/notificationSlice";

const Location = ({ locations}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dispatch = useDispatch();

  const itemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);

  if (!locations) {
    return <LoadingIcon color={colors.blueAccent[100]} />;
  }

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentLocations = locations.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(locations.length / itemsPerPage);

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };
  
  const handleLocationRefresh = () => {
    dispatch(refreshLocationList()).then(() => {
      const notification = {
        message: "Location list refreshed",
        severity: "success",
      };
      dispatch(setNotification(notification));
    }).catch((error) => {
      const notification = {
        message: error.message,
        severity: "error",
      };
      dispatch(setNotification(notification));
    }
    );
  }


  return (
    <Box p={"5px"} sx={{ backgroundColor: colors.primary[400], borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
      {locations.length === 0 ? (
        <Box display="flex">
          <Button
          onClick={handleLocationRefresh}
              sx={{
                display: "inline-block",
                textTransform: "none",
                backgroundColor: "transparent",
                borderRadius: "15px",
                width: "125px",
                border: "1px solid transparent",
                borderColor: colors.blueAccent[100],
                "@media (max-width: 940px)": {
                  width: "85px",
                },
                "@media (max-width: 600px)": {
                  width: "10px",
                }
              }}
            >
              <Typography color={colors.blueAccent[100]} sx={{ "@media (max-width: 600px)": { fontSize: "8px", } }}>Refresh List</Typography>
            </Button>
            <Typography sx={{ textAlign: "center", color: colors.grey[500], marginTop: 2, marginLeft: 66}}>
              No results found, try refreshing the list.
            </Typography>
        </Box>

      ) : (
        <>
          <Grid container spacing={2} columns={{ xs: 8, sm: 16, md: 16 }}>
            {currentLocations.map((location) => (
              <TableStyle
                key={location.location_id}
                location={{
                  location_id: location.location_id,
                  cameras_count: location.cameras_count,
                  location_name: location.location_name,
                  is_active: location.is_active,
                  bay_count: location.bay_count,
                  host_ip: location.host_ip,
                  
                }}
              />
            ))}
          </Grid>
          <Box mt={2} display="flex" justifyContent="left">
            <Button
              onClick={handleLocationRefresh}
              sx={{
                display: "inline-block",
                textTransform: "none",
                backgroundColor: "transparent",
                borderRadius: "15px",
                width: "125px",
                border: "1px solid transparent",
                borderColor: colors.blueAccent[100],
                "@media (max-width: 940px)": {
                  width: "85px",
                },
                "@media (max-width: 600px)": {
                  width: "10px",
                }
              }}
            >
              <Typography color={colors.blueAccent[100]} sx={{ "@media (max-width: 600px)": { fontSize: "8px", } }}>Refresh List</Typography>
            </Button>
          </Box>

          <Box mt={2} display="flex" justifyContent="center">
            {currentPage !== 1 && (
              <Button
                onClick={() => handlePageChange(currentPage - 1)}
                sx={{
                  display: "inline-block",
                  textTransform: "none",
                  backgroundColor: "transparent",
                  borderRadius: "15px",
                  width: "125px",
                  border: "1px solid transparent",
                  borderColor: colors.blueAccent[100],
                  "@media (max-width: 940px)": {
                    width: "85px",
                  },
                  "@media (max-width: 600px)": {
                    width: "10px",
                  }
                }}
              >
                <Typography color={colors.blueAccent[100]} sx={{ "@media (max-width: 600px)": { fontSize: "8px", } }}>Previous Page</Typography>
              </Button>
            )}

            {currentPage < totalPages && (
              <Button
                onClick={() => handlePageChange(currentPage + 1)}
                sx={{
                  display: "inline-block",
                  textTransform: "none",
                  backgroundColor: "transparent",
                  borderRadius: "15px",
                  width: "125px",
                  border: "1px solid transparent",
                  borderColor: colors.blueAccent[100],
                  "@media (max-width: 940px)": {
                    width: "85px",
                  },
                  "@media (max-width: 600px)": {
                    width: "10px",
                  }
                }}
              >
                <Typography color={colors.blueAccent[100]} sx={{ "@media (max-width: 600px)": { fontSize: "8px", } }}>Next Page</Typography>
              </Button>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default Location;

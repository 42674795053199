
import { Box, useTheme, Typography, Slider, Switch, Stack } from "@mui/material";
import { tokens } from '../../theme';
import { useSelector, useDispatch } from 'react-redux';
import { changeSettingValue, setGainAuto, selectGainAuto, selectSpecificSettingValue} from '../../redux/slices/controllerSlice';
import { useEffect } from "react";

const SliderComp = ({ title, handleChange, text, nickname, value}) => {

    const gainAuto = useSelector(selectGainAuto);
    const dispatch = useDispatch();
    const handleAutoToggle = () => {
        dispatch(setGainAuto(!gainAuto));
        dispatch(changeSettingValue({ title: 'GainAuto', value: !gainAuto.toString()}));
    }
    
    if (title === 'Gain') {
        
        return (
            <Box width="100%" display="flex" flexDirection="column" gap="10px">
            <Typography variant="h6" sx={text}>{title}</Typography>
                <Stack direction="row" spacing={1} alignItems="center">
                    <Typography>Auto</Typography>
                    <Switch defaultChecked={gainAuto} onChange={() => handleAutoToggle()} color="info" />
                    <Typography>Manual</Typography>
                </Stack>
            <Box px={"10px"}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"10px"} > 0 </Typography>
                    <Typography fontSize={"10px"} > 100 </Typography>
                </Box>
                <Slider
                    disabled={!gainAuto}
                    valueLabelDisplay="auto"
                    value={value}
                    steps={1}
                    onChange={(event, newValue) => handleChange(event, newValue, title)}  // Pass both event and newValue
                    size="small"
                    sx={{ p: "6px 0px", color: "#1F8FF2" }}
                    min={0}
                    max={100}
                />
            </Box>
        </Box>
        )
    }

    return (
        <Box width="100%" display="flex" flexDirection="column" gap="10px">
            <Typography variant="h6" sx={text}>{title}</Typography>
            <Box px={"10px"}>
                <Box display={"flex"} justifyContent={"space-between"}>
                    <Typography fontSize={"10px"} > 0 </Typography>
                    <Typography fontSize={"10px"} > 100 </Typography>
                </Box>
                <Slider
                valueLabelDisplay="auto"
                value={value}
                steps={1}
                onChange={(event, newValue) => handleChange(event, newValue, title)}  // Pass both event and newValue
                size="small"
                sx={{ p: "6px 0px", color: "#1F8FF2" }}
                min={0}
                max={100}
            />
            </Box>
        </Box>
    )
}

export default SliderComp
import { useEffect, useState } from "react";
import {useDispatch, useSelector} from "react-redux";
import { Routes, Route } from "react-router-dom"; // Import Navigate for redirection
import Sidebar from "../src/scenes/global/Sidebar";
import Dashboard from "../src/scenes/dashboard";
import Search from "../src/scenes/Search";
import Notes from "../src/scenes/Locations";
import Login from "../src/scenes/Login"; // Import your Login component
import { CssBaseline, ThemeProvider } from "@mui/material";
import { ColorModeContext, useMode } from "./theme";
import {checkCurrentUser, selectCurrentUser } from './redux/slices/userSlice';
function App() {
  // Use the 'useMode' hook to get the theme and colorMode
  const [theme, colorMode] = useMode();
  const dispatch = useDispatch();
  const isCurrentUser = useSelector(selectCurrentUser);

  useEffect(() => {
    dispatch(checkCurrentUser());
  }, [dispatch]);

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className="app">
          {!isCurrentUser ? ( // If user not authenticated, show the login component
            <Login />
          ) : (
            <>
              <Sidebar />
              <main className="content">
                <Routes>
                  <Route path="/" element={<Dashboard />} />
                  {/* <Route path="/Search" element={<Search />} />
                  <Route path="/Locations" element={<Notes />} /> */}
                </Routes>
              </main>
            </>
          )}
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;

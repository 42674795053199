// Import the 'client' instance from axiosConfig.js
import client from './axiosConfig';

import { createSlice, createAction, createAsyncThunk } from '@reduxjs/toolkit';
import { apiUrl } from './apiConfig';

// No need to set default Axios configurations here

export const setCurrentUser = createAction('user/setCurrentUser');
export const setFirstName = createAction('user/setFirstName');
export const setLastName = createAction('user/setLastName');

const userSlice = createSlice({
    name: 'user',
    initialState: {
        email: null,
        password: null,
        first_name: "",
        last_name: "",
        registrationToggle: false,
        currentUser: false,
        
    },
    reducers: {
        setRegistrationToggle: (state, action) => {
            state.registrationToggle = action.payload
        },
        setEmail: (state, action) => {
            state.email = action.payload
        },
        setPassword: (state, action) => {
            state.password = action.payload
        },
        setIsAuthenticated: (state, action) => {
            state.isAuthenticated = action.payload;
        },
        setIsInitialized: (state, action) => {
            state.isInitialized = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(setCurrentUser, (state, action) => {
                state.currentUser = action.payload
            })
            .addCase(setFirstName, (state, action) => {
                state.first_name = action.payload
            })
            .addCase(setLastName, (state, action) => {
                state.last_name = action.payload
            });
    },
});


export const login = createAsyncThunk('user/login', async ({email, password}, {dispatch}) => {
    const response = await client.post(`${apiUrl}login/`, {
        email,
        password,
    });
    if (response.status === 200) {
        dispatch(setCurrentUser(true));
    } else {
        dispatch(setCurrentUser(false));
    }
});

export const checkCurrentUser = createAsyncThunk('user/checkCurrentUser', async (_, {dispatch}) => {
   const response = await client.get(`${apiUrl}user/`);
   //if response is 200, dispatch set current user with true, else set to false
   if(response.status === 200) {
       dispatch(setCurrentUser(true));
       dispatch(setFirstName(response.data.user.first_name));
       dispatch(setLastName(response.data.user.last_name));
   } else {
       dispatch(setCurrentUser(false));
   }
});

export const logout = createAsyncThunk('user/logout', async (_, { dispatch }) => {
    try {
        const response = await client.post(`${apiUrl}logout/`);
        if(response.status === 200) {
            dispatch(setCurrentUser(false));
        } 
    } catch (error) {
        console.error(error);
    }
});

export const register = createAsyncThunk('user/register', async ({email, password}, {dispatch}) => {
    const response = await client.post(`${apiUrl}register/`, {
        email,
        password,
    });
    if (response.status === 200) {
        dispatch(setCurrentUser(true));
    } else {
        dispatch(setCurrentUser(false));
    }
});

export const { setIsAuthenticated, setIsInitialized, setEmail, setPassword,setRegistrationToggle, } = userSlice.actions;
export const selectRegistrationToggle = (state) => state.user.registrationToggle;
export const selectFirstName = (state) => state.user.first_name;
export const selectLastName = (state) => state.user.last_name;
export const selectEmail = (state) => state.user.email;
export const selectPassword = (state) => state.user.password;
export const selectCurrentUser = (state) => state.user.currentUser;

export default userSlice.reducer;

import LiveFeedPlayer from "./Controller/LiveFeedPlayer"
import { Box, useTheme, Typography } from "@mui/material";
import { tokens } from "../theme";
import { useSelector } from 'react-redux';
import { selectCurrentStreamList, selectLoading} from '../redux/slices/streamSlice';
import VideoPlaceholder from "./VideoPlaceHolder";
import Loading from "./Loading";


const StreamList = ({host}) => {
    const streams = useSelector(selectCurrentStreamList);
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const loading = useSelector(selectLoading);


    const cameraBlockStyles = {
      padding: '5px',
      borderRadius: '10px',
      backgroundColor: colors.blueAccent[800],
    }

    const cameraIpStyles = {
      color: colors.grey[300],
      fontSize: "20px",
    }

    //test data
    // const streams = [
    //     {
    //         "camera_ip": "192.168.10.11",
    //         "url": "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
    //         "status": "running"
    //     },
    //     {
    //         "camera_ip": "192.168.10.12",
    //         "url": "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
    //         "status": "error"
    //     },
    //     {
    //         "camera_ip": "192.168.10.13",
    //         "url": "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
    //         "status": "error"
    //     },
    //     {
    //         "camera_ip": "192.168.10.14",
    //         "url": "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
    //         "status": "running"
    //     },
    //     {
    //         "camera_ip": "192.168.10.15",
    //         "url": "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
    //         "status": "running"
    //     },
    //     {
    //         "camera_ip": "192.168.10.16",
    //         "url": "https://bitdash-a.akamaihd.net/content/MI201109210084_1/m3u8s/f08e80da-bf1d-4e3d-8899-f0f6155f6efa.m3u8",
    //         "status": "error"
    //     },

    // ];

    // Sort streams based on camera_ip in ascending order
    const sortedStreams = [...streams].sort((a, b) => a.camera_ip.localeCompare(b.camera_ip));

  // Ensure there are only three rows in each column
    const numRows = 3;

  // Ensure there are at least 3 * 2 streams
    const minStreams = numRows * 2;
    const paddedStreams = sortedStreams.slice(0, minStreams);

    const driverSide= paddedStreams.slice(0, numRows).reverse();
    const passengerSide = paddedStreams.slice(numRows, minStreams).reverse();

    return (
      <Box display="flex" flexDirection="column" gap={2} sx={{ padding: '20px' }}>
        {loading ? (
          <Box
            flex="1"
            display="flex"
            justifyContent="center"
            alignItems="center"
            margin="10%"
          >
            <Loading color={colors.blueAccent[300]} />
          </Box>
        ) : (
          <Box display="flex" justifyContent="space-between" gap={2}>
            <Box display="flex" flexDirection="column" gap={2}>
              {driverSide.map((stream, index) => (
                <Box
                  key={index}
                  flex="1"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={2}
                  sx={cameraBlockStyles}
                >
                  <Typography sx={cameraIpStyles}>{stream.camera_ip}</Typography>

                  {stream.status === 'error' ? (
                    <VideoPlaceholder style={{ width: '100%', height: '100%' }} />
                  ) : (
                    <LiveFeedPlayer camera={stream.camera_number} host={host} />
                  )}
                </Box>
              ))}
            </Box>
            <Box display="flex" flexDirection="column" gap={2}>
              {passengerSide.map((stream, index) => (
                <Box
                  key={index}
                  flex="1"
                  display="flex"
                  flexDirection="column"
                  alignItems="center"
                  gap={2}
                  sx={cameraBlockStyles}
                >
                  <Typography sx={cameraIpStyles}>{stream.camera_ip}</Typography>
                  {stream.status === 'error' ? (
                    <VideoPlaceholder style={{ width: '100%', height: '100%' }} />
                  ) : (
                    <LiveFeedPlayer camera={stream.camera_number} host={host} />
                  )}
                </Box>
              ))}
            </Box>
          </Box>
        )}
      </Box>
    );
}
export default StreamList;

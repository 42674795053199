// axiosConfig.js

import axios from 'axios';

axios.defaults.xsrfCookieName = 'csrftoken';  // Ensure consistency with Django CSRF token cookie name
axios.defaults.xsrfHeaderName = 'X-CSRFToken';  // Ensure consistency with Django CSRF token header name
axios.defaults.withCredentials = true;  // Ensure credentials are sent with requests, necessary for cookie-based CSRF protection

// Add Axios interceptor for CSRF token inclusion
axios.interceptors.request.use(function (config) {
    const method = config.method.toUpperCase();
    if (method !== 'GET') {  // Ensure CSRF token is only included in non-GET requests
        config.headers['X-CSRFToken'] = getCookie('csrftoken');  // Get CSRF token from cookie
    }
    return config;
});

// Function to extract CSRF token from cookie
function getCookie(name) {
    const cookieValue = document.cookie.match('(^|;)\\s*' + name + '\\s*=\\s*([^;]+)');
    return cookieValue ? cookieValue.pop() : '';
}

export default axios;  // Export Axios instance

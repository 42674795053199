import React, { useEffect } from 'react';
import { Box, Button, useTheme, Typography } from "@mui/material";
import { tokens } from "../../theme";
import ControllerFooter from './ControllerFooter';
import ControllerHeader from './ControllerHeader';
import General from './General';
import Video from './Video';
import Color from './Color';
import LoadingIcon from '../Loading';
import { useSelector, useDispatch } from 'react-redux';
import { selectSelectedLocationId, selectSelectedLocationIp} from '../../redux/slices/locationSlice';
import { selectControllerView, setControllerView, fetchSettingsForSelectedCameras, setInitialDisplayedSettings, findModifiedSettings, selectModifiedSettings, setError, refreshCameras, selectLoading, postSettingByCameraList, selectSuccessfullPost, clearModifiedSettings} from '../../redux/slices/controllerSlice';
import { selectShowLiveFeed, toggleLiveFeed, stopStream ,startStream, selectCurrentStreamUrl, setLiveFeed} from '../../redux/slices/streamSlice';
import { selectSelectedCameras, selectCurrentCamera, selectCurrentCameraIp, selectSelectedIds, selectCurrentCameraId } from '../../redux/slices/cameraSlice';
import LiveFeedPlayer from './LiveFeedPlayer';
import { setCurrentView} from '../../redux/slices/generalSlice';
import {setNotification, openNotification } from '../../redux/slices/notificationSlice';
import Notification from '../../scenes/global/Notification';
/*
 current streams list example:

 currentStreams: [
      {
        camera_id: 806,
        camera_ip: '192.168.10.12',
        url: 'http://100.74.130.2/hls/cam2/stream.m3u8'
        status: 'running'
      }
    ]
*/

const Controller = ({handleBackClick}) => {

  const dispatch = useDispatch();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const controllerView = useSelector(selectControllerView);
  const currentCamera = useSelector(selectCurrentCamera);
  const selectedCameras = useSelector(selectSelectedCameras);
  const currentCameraIp = useSelector(selectCurrentCameraIp);
  const locationId = useSelector(selectSelectedLocationId);
  const modifiedSettings = useSelector(selectModifiedSettings);
  const selectedCamerasIds = useSelector(selectSelectedIds);
  const loading = useSelector(selectLoading);
  const successfull = useSelector(selectSuccessfullPost);
  const showLiveFeed = useSelector(selectShowLiveFeed);
  const currentCameraId = useSelector(selectCurrentCameraId);
  const currentStreamURL = useSelector(selectCurrentStreamUrl); 
  const host = useSelector(selectSelectedLocationIp);


  useEffect(() => {
    dispatch(fetchSettingsForSelectedCameras(selectedCameras))
      .then(() => {
        // This code will run after fetchSettingsForSelectedCameras is fulfilled
        dispatch(setInitialDisplayedSettings(currentCamera));
       
      })
      .catch((error) => {
        dispatch(setError(null,error.message));
      });
  }, [selectedCameras, currentCamera, currentStreamURL]);

  const btnStyle = {
    textTransform: "none",
    backgroundColor: "transparent",
    borderRadius: "15px",
    border: "1px solid transparent",
    borderColor: colors.blueAccent[100],
    padding: "6px 30px",
    "@media (max-width: 1080px)": {
      padding: "4px 14px",
    },
    "@media (max-width: 600px)": {
      padding: "4px 5px",
    }
  };

  const btnStyle2 = {
    textTransform: "none",
    backgroundColor: "transparent",
    borderRadius: "15px",
    border: "1px solid transparent",
    borderColor: '#D76761',
    padding: "6px 30px",
    "@media (max-width: 1080px)": {
      padding: "4px 20px",
    },
    "@media (max-width: 600px)": {
      padding: "4px 5px",
    }
  };
  const text = {
    "@media (max-width: 1080px)": {
      fontSize: "12px"
    }
  }

  
  const handleFindModifiedSettings = () => {
    dispatch(findModifiedSettings(currentCamera));
  }

  const handleViewChange = ({ view }) => {
    dispatch(setControllerView(view))
  };

  const handleToggleLiveFeed = () => {
    dispatch(showLiveFeed ? stopStream({locationId: locationId, cameraIds: [currentCameraId]}) : startStream({locationId: locationId, cameraIds: [currentCameraId]})).then(() => {
      dispatch(toggleLiveFeed())});
  };

  const handleSaveClick = async ({ isSingle }) => {
    if (selectedCameras.length === 0) {
      alert('Please select at least one camera');
      return;
    }
  
    
    let confirmationMessage = ""

    if (isSingle){
      confirmationMessage = 'Are you sure you want to save these settings?';
    } else {
      confirmationMessage = 'Are you sure you want to save these settings to all selected cameras?'
    }
    const confirm = window.confirm(confirmationMessage);
  
    if (!confirm) {
      return;
    }

    if (modifiedSettings.length === 0) {
      alert('No settings have been modified. Save operation cancelled.');
      return;
    }
    const chosenCameraIds = isSingle ? [currentCameraId] : selectedCamerasIds;
    try {
      const payload = {
        cameraIds: chosenCameraIds,
        locationId: locationId,
        settings: modifiedSettings
      };
      dispatch(postSettingByCameraList({ payload })).then(() => {
        const notification = {
          message: 'Settings saved successfully',
          severity: 'success'
        }
        dispatch(setNotification(notification));
        dispatch(openNotification());
      });
    } catch (error) {
      // Handle error if needed
      const notification = {
        message: 'Error saving settings',
        severity: 'error'
      }
      dispatch(setNotification(notification));
      dispatch(openNotification());

    }
    dispatch(refreshCameras({camera_ids: chosenCameraIds, location_id: locationId}));
    dispatch(clearModifiedSettings());
    dispatch(setCurrentView('controllers'))

  };

  const stopStreamOnNextPrevClick = () => {
    if (currentStreamURL !== null) {
      dispatch(stopStream({locationId: locationId, cameraIds: [currentCameraId]}));
      dispatch(setLiveFeed(false));
    }
  }

  
  

  return (
    <Box mt="30px">
      <ControllerHeader stopStreamOnNextPrevClick={stopStreamOnNextPrevClick}/>
      <Box padding="10px" sx={{ backgroundColor: colors.primary[400], borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>    
        {loading || successfull === false ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}>
            <LoadingIcon color={colors.blueAccent[100]} />
          </div>
        ) : (
          <div>
            <Box
              display="flex"
              justifyContent="space-between"
              padding="10px"
              sx={{
                "@media (max-width: 600px)": {
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                  padding: "1px"
                }
              }}
            >
              <Box display="flex" gap="25px" sx={{ "@media (max-width: 1080px)": { gap: "5px" } }}>
                <Button
                  sx={controllerView === "general" ? btnStyle2 : btnStyle}
                  onClick={() => handleViewChange({ view: "general" })}
                >
                  <Typography color={colors.blueAccent[100]} variant="h7" sx={text}>
                    General
                  </Typography>
                </Button>

                <Button
                  sx={controllerView === "video" ? btnStyle2 : btnStyle}
                  onClick={() => handleViewChange({ view: "video" })}
                >
                  <Typography color={colors.blueAccent[100]} variant="h7" sx={text}>
                    Video
                  </Typography>
                </Button>

                <Button
                  sx={controllerView === "color" ? btnStyle2 : btnStyle}
                  onClick={() => handleViewChange({ view: "color" })}
                >
                  <Typography color={colors.blueAccent[100]} variant="h7" sx={text}>
                    Color
                  </Typography>
                </Button>
              </Box>
              <Box display="flex" gap="10px">
                <Button
                  sx={showLiveFeed ? btnStyle2 : btnStyle}
                  onClick={handleToggleLiveFeed}
                >
                  <Typography color={showLiveFeed ? "#FF4848" : colors.blueAccent[100]} variant="h7" sx={text}>
                    {showLiveFeed ? "Stop Live Feed" : "Start Live Feed"}
                  </Typography>
                </Button>
                <Button sx={btnStyle}>
                  <Typography color={colors.blueAccent[100]} variant="h7" sx={text}>{`Camera ${currentCameraIp}`}</Typography>
                </Button>
              </Box>
            </Box>
            <Box padding="10px">
              <Box>
                {controllerView === "general" ? (
                  <General findModified={handleFindModifiedSettings} />
                ) : controllerView === "video" ? (
                  <Video findModified={handleFindModifiedSettings} />
                ) : (
                  <Color findModified={handleFindModifiedSettings} />
                )}
              </Box>
              <Box>
                {(showLiveFeed && currentStreamURL !== null) && < LiveFeedPlayer camera={currentCameraIp.slice(-1)} host={host}/>}
              </Box>
            </Box>
          </div>
        )}
        <ControllerFooter ClickSave={handleSaveClick} onBackClick={handleBackClick} />
      </Box>
    </Box>
  );
};

export default Controller;

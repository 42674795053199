import React from "react";
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";

import SliderComp from "./SliderComp";
import { useSelector, useDispatch } from "react-redux";
import { selectSpecificSettingValue, changeSettingValue} from "../../redux/slices/controllerSlice";
const Color = ({findModified}) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();


  const btnStyle = {
    textTransform: "none",
    backgroundColor: "transparent",
    borderRadius: "15px",
    border: "1px solid transparent",
    borderColor: colors.blueAccent[100],
    padding: "6px 30px"
  };
  const text ={
    "@media (max-width: 1080px)": {
      fontSize: "12px"
    }
  }

 
  const handleBrightnessChange = (event, newValue) => {
    if (typeof newValue !== 'string') {
      newValue = newValue.toString();
    }
    dispatch(changeSettingValue({ title: 'Brightness', value: newValue }));
    findModified();
  };
  const handleSaturationChange = (event, newValue) => {
    if (typeof newValue !== 'string') {
      newValue = newValue.toString();
    }
    dispatch(changeSettingValue({ title: 'Saturation', value: newValue }));
    findModified();
  };
  const handleContrastChange = (event, newValue) => {
    if (typeof newValue !== 'string') {
      newValue = newValue.toString();
    }
    dispatch(changeSettingValue({ title: 'Contrast', value: newValue}));
    findModified();
  };



  return (
    <Box>
      <Box display="flex" width="100%" gap="20px" mb="40px" sx={{'@media (max-width: 600px)': {display: 'flex', flexDirection: "column",gap:"10px"}}}>
        <SliderComp title="Brightness" handleChange={(event, newValue) => handleBrightnessChange(event, newValue)} text={text} nickname={"Brightness"} value={parseInt(useSelector((state) => selectSpecificSettingValue(state, 'Brightness')))}/>
        <SliderComp title="Saturation" handleChange={(event, newValue) => handleSaturationChange(event, newValue)} text={text} nickname={"Saturation"} value={parseInt(useSelector((state) => selectSpecificSettingValue(state, 'Saturation')))}/>
        <SliderComp title="Contrast" handleChange={(event, newValue) => handleContrastChange(event, newValue)} text={text} nickname={"Contrast"} value={parseInt(useSelector((state) => selectSpecificSettingValue(state, 'Contrast')))}/>
      </Box>
    </Box>
  );
};

export default Color;

import React from 'react';
import { Box, Button, useTheme, Typography} from "@mui/material";
import { tokens } from "../../theme";
import Switch from '@mui/material/Switch';
import { useSelector, useDispatch } from 'react-redux';
import { selectControllerView, setInitialDisplayedSettings, toggleDay, clearSettings, selectRefreshingCameras } from '../../redux/slices/controllerSlice';
import { selectSelectedCameras, selectCurrentCamera, selectSelectAll, selectSelectedButtons} from '../../redux/slices/cameraSlice';
import LoadingIcon from '../Loading';

const ControllerFooter = ({ClickSave, onBackClick}) => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const controllerView = useSelector(selectControllerView);
    const currentCamera = useSelector(selectCurrentCamera);
    const selectedCameras = useSelector(selectSelectedCameras);
    const selectedButtons = useSelector(selectSelectedButtons);
    const isSelectAll = useSelector(selectSelectAll);
    const isRefreshing = useSelector(selectRefreshingCameras);
  
    const saveButtonText = isSelectAll ? "Save to All" : `Save to ${selectedButtons.join(", ")}`;
  
    const handleSaveClick = ({isSingle}) => {
      ClickSave({isSingle: isSingle});
    }
  
    const handleToggleDayNight = () => {
      dispatch(toggleDay());
      
    }
  
    const handleClearClick = () => {
      dispatch(clearSettings());
      dispatch(setInitialDisplayedSettings(currentCamera));
    }
  
    const btnStyle = {
      textTransform: "none",
      backgroundColor: "transparent",
      borderRadius: "15px",
      border: "1px solid transparent",
      borderColor: colors.blueAccent[100],
      padding: "6px 30px",
      "@media (max-width: 1080px)": {
        padding: "4px 14px",
      },
      "@media (max-width: 600px)": {
        padding: "4px 5px",
      }
    };
  
    const text = {
      "@media (max-width: 1080px)": {
        fontSize: "12px"
      }
    };
    return (
      <Box display="flex" justifyContent="space-between" sx={{ '@media (max-width: 600px)': { display: 'flex', flexDirection: "column", gap: "10px" } }}>
        {/* Only show next element if controllerView is not general */}
        {isRefreshing && <LoadingIcon color={colors.blueAccent[100]} />}
        {controllerView !== "general" && (
          <Box display={"flex"} alignItems={"center"}>
            <Typography sx={text}>Night</Typography>
            <Switch defaultChecked color="warning"  onClick={handleToggleDayNight}/>
            <Typography sx={text}>Day</Typography>
          </Box>
        )}
        {controllerView === "general" && (
          <Box display={"flex"} alignItems={"center"}>
        </Box>
        )}
        <Box display="flex" gap="20px" sx={controllerView === 'general' ? { justifyContent: 'flex-end' } : {}}>
          <Button sx={btnStyle} onClick={onBackClick}>
            <Typography color={colors.blueAccent[100]} variant="h7" sx={text}>
              Back
            </Typography>
          </Button>
          <Button sx={btnStyle} onClick={handleClearClick} >
            <Typography color={colors.blueAccent[100]} variant="h7" sx={text}>
              Clear
            </Typography>
          </Button>
          <Box sx={{ display: "flex", backgroundColor: "#1F8FF2", borderRadius: "12px" }}>
            <Button sx={{ color: colors.grey[100], textTransform: "none", p: "10px 50px", '@media (max-width: 600px)': { p: "4px 5px" } }} onClick={() => handleSaveClick({isSingle: true})}>
              <Typography variant="h7" sx={text}>
                Save
              </Typography>
            </Button>
          </Box>
          { selectedCameras.length > 1 && (
             <Box sx={{ display: "flex", backgroundColor: "#1F8FF2", borderRadius: "12px" }}>
             <Button sx={{ color: colors.grey[100], textTransform: "none", p: "10px 50px", '@media (max-width: 600px)': { p: "4px 5px" } }} onClick={() => handleSaveClick({isSingle: false})}>
               <Typography variant="h7" sx={text}>
                 {saveButtonText}
               </Typography>
             </Button>
           </Box>
          )}
        </Box>
  
      </Box>
    )
  
  }

  export default ControllerFooter
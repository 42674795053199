import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';


const LoadingIcon = ({ size = 50, color }) => {
  return (
    <CircularProgress
      size={size}
      thickness={4}
      style={{ color: color }} // Change color as needed
    />
  );
};

export default LoadingIcon;

import { useEffect } from 'react';
import { Box, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import { fetchCameraList } from '../../redux/slices/cameraSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedLocationId } from '../../redux/slices/locationSlice';
import Camera from './Camera';
import LoadingIcon from '../Loading';
import { selectLoading } from '../../redux/slices/controllerSlice';



const CamerasList = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const dispatch = useDispatch();
  const loadingCameraSettings = useSelector(selectLoading);
  const currentLocationId = useSelector(selectSelectedLocationId);
  
  const fetchData = async () => {
    dispatch(fetchCameraList(currentLocationId));
  };
  
  useEffect(() => {
    fetchData();
  }, [dispatch, currentLocationId]);
 
  return (
    <Box sx={{ backgroundColor: colors.primary[400], borderBottomLeftRadius: "20px", borderBottomRightRadius: "20px" }}>
      <Camera/>
      <Box display={"flex"} justifyContent={"center"}>
      {loadingCameraSettings && <LoadingIcon color={colors.blueAccent[100]} />}
      </Box>
    </Box>
    

  );
};

export default CamerasList;
import { createSlice } from '@reduxjs/toolkit';


/*
available views:
- locations
- cameras
- controllers
- bays
- streams

*/

const initialState = {
    currentView: 'locations',
};

const generalSlice = createSlice({
    name: 'general',
    initialState,
    reducers: {
        setCurrentView: (state, action) => {
            state.currentView = action.payload;
        },
    },
});

export const { setCurrentView } = generalSlice.actions;
export const selectCurrentView = (state) => state.general.currentView;

export default generalSlice.reducer;
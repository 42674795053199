
//Local Dev
// export const apiUrl='http://127.0.0.1:8000/api/'
// export const streamUrlBase='http://127.0.1:8000/hls/'

//Production
export const apiUrl='https://siletz.baywatchtechnologies.com/api/'
export const streamUrlBase='https://siletz.baywatchtechnologies.com/hls/'

//EC2 Dev
//export const apiUrl='https://dev.baywatch.tech/api/'
//export const streamUrlBase='https://dev.baywatch.tech/hls/'

import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    notification: {
        message: 'Success',
        severity: 'success',
    },
    notificationOpen: false,
};

const notificationSlice = createSlice({
    name: 'notification',
    initialState,
    reducers: {
        setNotification: (state, action) => {
            state.notification = action.payload;
            state.notificationOpen = true;
        },
        closeNotification: (state) => {
            state.notificationOpen = false;
        },
        openNotification: (state) => {
            state.notificationOpen = true;
        }
    },
});

export const {setNotification, closeNotification, openNotification } = notificationSlice.actions;

export const selectNotification = (state) => state.notification.notification;
export const selectNotificationOpen = (state) => state.notification.notificationOpen;

export default notificationSlice.reducer;
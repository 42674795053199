import { createContext, useState, useMemo } from "react";
import { createTheme } from "@mui/material/styles";

// Define a function called 'tokens' that returns design tokens based on a given mode (light or dark)
export const tokens = (mode) => ({
  // Design tokens for dark mode
  ...(mode === "dark"
    ? {
        grey: {
          100: "#e0e0e0",
          200: "#FFFFFF",
          300: "#FFFFFF",
          400: "#666666",
          500: "#666666",
          600: "#525252",
          700: "#FFFFFF",
          800: "#1F8FF2",
          900: "#161C23",
        },
        primary: {
          100: "#FFFFFF",
          200: "#a1a4ab",
          300: "#727681",
          400: "#161C23",
          500: "#0D1116",
          600: "#101624",
          700: "#0c101b",
          800: "#080b12",
          900: "#FFFFFF",
        },
        greenAccent: {
          100: "#161C23",
          200: "#1C3043",
          300: "#0E2724",
          400: "#00C9B0",
          500: "#666666",
          600: "#3da58a",
          700: "#122B42",
          800: "#1e5245",
          900: "#0f2922",
        },
        redAccent: {
          100: "#242B33",
          200: "#272D35",
          300: "#0D1116",
          400: "#161C23",
          500: "#db4f4a",
          600: "#af3f3b",
          700: "#1E364C",
          800: "#7A7A7A",
          900: "#2c100f",
        },
        blueAccent: {
          100: "#4A94D6",
          200: "#FFFFFF",
          300: "#1F8FF2",
          400: "#17344F",
          500: "#1F8FF2",
          600: "#182A3C",
          700: "#3775AB",
          800: "#1C374F",
          900: "#242B33",
        },
      }
    : {
      // Design tokens for light mode
        grey: {
          100: "#ffffff",
          200: "#000000",
          300: "#1E1E1E",
          400: "#BDBDBD",
          500: "#999999",
          600: "#858585",
          700: "#8D8D8D",
          800: "#262835",
          900: "#262835",
        },
        primary: {
          100: "#1F2749",
          200: "#080b12",
          300: "#0c101b",
          400: "#E3F2FF", // manually changed
          500: "#141b2d",
          600: "#1F2A40",
          700: "#727681",
          800: "#4A4A4A",
          900: "#262835",
        },
        greenAccent: {
          100: "#F9F9F9",
          200: "#FFFFFF",
          300: "#E7F8F6",
          400: "#2A9D8F",
          500: "#D9D9D9",
          600: "#70d8bd",
          700: "#E8EFF6",
          800: "#b7ebde",
          900: "#dbf5ee",
        },
        redAccent: {
          100: "#3D4043",
          200: "#E4EFF9",
          300: "#FFFFFF",
          400: "#000000",
          500: "#db4f4a",
          600: "#e2726e",
          700: "#E3F2FF",
          800: "#262835",
          900: "#f8dcdb",
        },
        blueAccent: {
          100: "#4A94D6",
          200: "#B2B1B0",
          300: "#4A94D6",
          400: "#D4EBFF",
          500: "#1F8FF2",
          600: "#F9F9F9",
          700: "#B1CDE5",
          800: "#DFEBF5",
          900: "#37373E",
        },
      }),
});

// function 'themeSettings' that defines MUI theme settings based on the color mode
export const themeSettings = (mode) => {
  const colors = tokens(mode);
  return {
    palette: {
      mode: mode,
      ...(mode === "dark"
        ? {
            // palette values for dark mode
            primary: {
              main: colors.primary[500],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: colors.primary[500],
            },
          }
        : {
            // palette values for light mode
            primary: {
              main: colors.primary[100],
            },
            secondary: {
              main: colors.greenAccent[500],
            },
            neutral: {
              dark: colors.grey[700],
              main: colors.grey[500],
              light: colors.grey[100],
            },
            background: {
              default: "#fcfcfc",
            },
          }),
    },
    typography: {
      fontFamily: ["Inter", "sans-serif"].join(","),
      // font sizes for different typography elements
      fontSize: 12,
      h1: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 40,
      },
      h2: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 32,
      },
      h3: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 22,
      },
      h4: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 20,
      },
      h5: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 16,
      },
      h6: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 14,
      },
      h7: {
        fontFamily: ["Inter", "sans-serif"].join(","),
        fontSize: 15,
      }
    },
  };
};

// context for color mode
export const ColorModeContext = createContext({
  toggleColorMode: () => {},
});
// custom hook 'useMode' to manage color mode and theme
export const useMode = () => {
  const [mode, setMode] = useState("dark");

 // colorMode object with a function to toggle the color mode
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () =>
        setMode((prev) => (prev === "light" ? "dark" : "light")),
    }),
    []
  );
 // the MUI theme based on the color mode
  const theme = useMemo(() => createTheme(themeSettings(mode)), [mode]);
  return [theme, colorMode];
};

// locationSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import client from './axiosConfig';
import { apiUrl } from './apiConfig'; 

const initialState = {
  data: [],
  error: null,
  loading: false,
  selectedLocationId: null,
  selectedLocationName: null,
  selectedLocationStatus: false,
  selectedLocationIp: null,
  selectedBay: 0,
  selectedLocationBayCount: 0,
  searchTerm: '',
};

// Async thunk for fetching all locations
export const fetchAllLocations = createAsyncThunk('location/fetchAllLocations', async () => {
  try {
    const response = await client.get(`${apiUrl}locations/`);
    return response.data;
  } catch (error) {
    console.error('Error fetching all locations:', error);
    throw error;
  }
});

export const refreshLocationList = createAsyncThunk('location/refreshLocationList', async () => {
  try {
    const response = await client.get(`${apiUrl}refresh_location_list/`);
    return response.data;
  } catch (error) {
    console.error('Error refreshing location list:', error);
    throw error;
  }
})

// Async thunk for fetching a single location
export const fetchLocation = createAsyncThunk('location/fetchLocation', async (id) => {
  try {
    const response = await client.get(`${apiUrl}locations/${id}`);
    return response.data;
  } catch (error) {
    throw error;
  }
});

const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    storeLocationId: (state, action) => {
      state.selectedLocationId = action.payload;
    },
    storeLocationName: (state, action) => {
      state.selectedLocationName = action.payload;
    },
    storeLocationStatus: (state, action) => {
      state.selectedLocationStatus = action.payload;
    },
    setSelectedBay: (state, action) => {  
      state.selectedBay = action.payload;
    },
    storeBayCount: (state, action) => {
      state.selectedLocationBayCount = action.payload;
    },
    storeLocationIp: (state, action) => {
      state.selectedLocationIp = action.payload;
    },
    setSearchTerm: (state, action) => {
      state.searchTerm = action.payload;
    },

  },
  extraReducers: (builder) => {
    // Handle the fetchAllLocations pending and fulfilled states
    builder.addCase(fetchAllLocations.pending, (state) => {
      state.error = null;
      state.loading = true;
    });
    builder.addCase(fetchAllLocations.fulfilled, (state, action) => {
      state.data = action.payload;
      state.loading = false;
    });
    builder.addCase(fetchAllLocations.rejected, (state, action) => {
      state.error = {
        message: action.error.message,
        status: action.error.response ? action.error.response.status : null,
      };
      state.loading = false;
    });

    // Handle the fetchLocation pending and fulfilled states
    builder.addCase(fetchLocation.pending, (state) => {
      state.error = null;
    });
    builder.addCase(fetchLocation.fulfilled, (state, action) => {
      state.data = action.payload;
    });
    builder.addCase(fetchLocation.rejected, (state, action) => {
      state.error = {
        message: action.error.message,
        status: action.error.response ? action.error.response.status : null,
      };
    });

    // Handle the refreshLocationList pending and fulfilled states
    builder.addCase(refreshLocationList.pending, (state) => {
      state.error = null;
      state.loading = true;
    });
    builder.addCase(refreshLocationList.fulfilled, (state) => {
      state.loading = false;
    });
    builder.addCase(refreshLocationList.rejected, (state, action) => {
      state.loading = false;
      state.error = {
        message: action.error.message,
        status: action.error.response ? action.error.response.status : null,
      };
    });
  },
});

export const { storeLocationId, storeLocationName, storeLocationStatus, setSelectedBay, storeBayCount, storeLocationIp, setSearchTerm } = locationSlice.actions;
export const selectSelectedLocationId = (state) => state.location.selectedLocationId;
export const selectSelectedLocationName = (state) => state.location.selectedLocationName;
export const selectSelectedLocationStatus = (state) => state.location.selectedLocationStatus;
export const selectSelectedBay = (state) => state.location.selectedBay;
export const selectSelectedLocationBayCount = (state) => state.location.selectedLocationBayCount;
export const selectSelectedLocationIp = (state) => state.location.selectedLocationIp;
export const selectLocationLoading = (state) => state.location.loading;
export const selectSearchTerm = (state) => state.location.searchTerm;
export const selectData = (state) => state.location.data;



export default locationSlice.reducer;

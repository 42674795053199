
import React, { useState } from "react";
import DropDownBox from "./DropDownBox";
import { Box } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { changeSettingValue } from "../../redux/slices/controllerSlice";
import SliderComp from './SliderComp';
import { selectSpecificSettingValue, translateAntiFlicker, translateColorMode, translateExposure } from "../../redux/slices/controllerSlice";



const Video = ({findModified}) => {
  const dispatch = useDispatch();


  const text = {
    "@media (max-width: 1080px)": {
      fontSize: "12px",
    },
  };

  const [exposureAnchorEl, setExposureAnchorEl] = useState(null);
  const [dayNightColorAnchorEl, setDayNightColorAnchorEl] = useState(null);
  const [antiFlickerAnchorEl, setAntiFlickerAnchorEl] = useState(null);

  const handleGainChange = (event, newValue) => {
    //make sure newValue is a string, if not make it a string

    if (typeof newValue !== 'string') {
      newValue = newValue.toString();
    }
    dispatch(changeSettingValue({ title: 'Gain', value: newValue}));
    findModified()
  };

  const handleExposureChange = (event, newValue) => {
    if (newValue === 'Auto') {
      dispatch(changeSettingValue({ title: 'ExposureMode', value: '0'}));
    } else {
      newValue = translateExposure(newValue);
      dispatch(changeSettingValue({ title: 'ExposureMode', value: '4'}));
      dispatch(changeSettingValue({ title: 'ExposureValue1', value: newValue}));
      dispatch(changeSettingValue({ title: 'ExposureValue2', value: newValue}));
    }
    findModified()
   
  }

  const handleDayNightColorChange = (event, newValue) => {
    dispatch(changeSettingValue({ title: 'DayNightColor', value: translateColorMode(newValue)}));
    findModified()
  }

  const handleAntiFlickerChange = (event, newValue) => {
    dispatch(changeSettingValue({ title: 'AntiFlicker', value: translateAntiFlicker(newValue)}));
    findModified()
  }


  const handleDropdownClick = (event, anchorElSetter) => {
    anchorElSetter(event.currentTarget);
  };

  const handleDropdownClose = (anchorElSetter) => {
    anchorElSetter(null);
  };

  

  const colorOptions = [
    'Color',
    'B&W',
    'Auto',
  ]

  const exposureOptions = [
    'Auto', 
    '1/3',
    '1/4',
    '1/5',
    '1/6',
    '1/120',
    '1/250',
    '1/500',
    '1/1000',
    '1/2000',
  ]

  const antiFlickerOptions = [
    'Auto',
    'Outdoor',
    '50hz',
    '60hz',
  ]

  return (
    <Box>
      <Box display="flex" width="100%" gap="20px" mb="40px">
        <DropDownBox
          title="Exposure"
          anchorEl={exposureAnchorEl}
          setAnchorEl={setExposureAnchorEl}
          handleDropdownClick={handleDropdownClick}
          handleDropdownClose={handleDropdownClose}
          options={exposureOptions}
          handleChange={handleExposureChange}
          nickname="ExposureMode"
        />
        <DropDownBox
          title="DayNightColor"
          anchorEl={dayNightColorAnchorEl}
          setAnchorEl={setDayNightColorAnchorEl}
          handleDropdownClick={handleDropdownClick}
          handleDropdownClose={handleDropdownClose}
          options={colorOptions}
          handleChange={handleDayNightColorChange}
          nickname="DayNightColor"
        />
      </Box>
      <Box display="flex" width="100%" gap="20px" mb="40px">
        <DropDownBox
          title="Anti-Flicker"
          anchorEl={antiFlickerAnchorEl}
          setAnchorEl={setAntiFlickerAnchorEl}
          handleDropdownClick={handleDropdownClick}
          handleDropdownClose={handleDropdownClose}
          options={antiFlickerOptions}
          handleChange={handleAntiFlickerChange}
          nickname="AntiFlicker"
        />
        <SliderComp
          title="Gain"
          handleChange={(event, newValue) => handleGainChange(event, newValue)}
          text={text}
          nickname="Gain"
          value={parseInt(useSelector((state) => selectSpecificSettingValue(state, 'Gain')))}
        />
      </Box>
    </Box>
  );
};



export default Video;
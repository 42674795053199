import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Box, Button, useTheme, Typography } from "@mui/material";
import { tokens } from "../theme";
import { selectSelectedLocationBayCount, setSelectedBay, selectSelectedBay, selectSelectedLocationId } from '../redux/slices/locationSlice';
import { selectSelectedIds, setSelectedIdsByBay, selectCameras } from '../redux/slices/cameraSlice';

const BayChooser = ({handleViewStreamsClick}) => {
    const bayCount = useSelector(selectSelectedLocationBayCount);
    const dispatch = useDispatch();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const selectedBay = useSelector(selectSelectedBay);
    const selectedCamerasIds = useSelector(selectSelectedIds);
    const selectedLocation = useSelector(selectSelectedLocationId);
    const cameras = useSelector(selectCameras);
    
    const btnStyle = {
        textTransform: "none",
        backgroundColor: "transparent",
        borderRadius: "15px",
        border: "1px solid transparent",
        borderColor: colors.blueAccent[100],
        padding: "6px 30px",
        "@media (max-width: 1080px)": {
            padding: "4px 14px",
        },
        "@media (max-width: 600px)": {
            padding: "4px 5px",
        }
    };

    const btnStyle2 = {
        textTransform: "none",
        backgroundColor: "transparent",
        borderRadius: "15px",
        border: "1px solid transparent",
        borderColor: colors.blueAccent[100],
        padding: "6px 30px",
        "@media (max-width: 1080px)": {
            padding: "4px 20px",
        },
        "@media (max-width: 600px)": {
            padding: "4px 5px",
        }
    };
    const text = {
        "@media (max-width: 1080px)": {
            fontSize: "12px"
        }
    }

    const border = {
        border: "1px solid",
        borderColor: colors.blueAccent[700],
        borderRadius: "15px",
        padding: "6px 30px",
        "@media (max-width: 1080px)": {
            padding: "4px 14px",
        },
        "@media (max-width: 600px)": {
            padding: "4px 5px",
        }
    }

    const handleBayClick = (bayNumber) => {
        dispatch(setSelectedBay(bayNumber));
        dispatch(setSelectedIdsByBay(bayNumber));
    };

    // return a small box that displays the bay numbers as buttons and when a bay is clicked, it will change the selectedBay state. Show the bay numbers from 1 to bayCount, and display the selectedBay state.
    return (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', flexDirection: 'column' }}>
            <Box>
            </Box>
            <Box border={border} margin='50px' backgroundColor={colors.primary[400]} >
                <Box margin='25px'>
                    <Typography sx={{ textAlign: 'center', fontSize: '35px' }}>
                        Bay Chooser
                    </Typography>
                </Box>
                {/* First row with title */}

                {/* Second row with buttons */}
                <Box>
                    {Array.from({ length: bayCount }, (_, i) => i + 1).map((bay) => (
                        <Button
                            onClick={() => handleBayClick(bay)}
                            sx={{
                                textTransform: "none",
                                backgroundColor: selectedBay === bay ? colors.blueAccent[800] : "transparent",
                                borderRadius: "15px",
                                width: "125px",
                                border: "1px solid transparent",
                                borderColor: colors.blueAccent[100],
                                "&:hover": {
                                    backgroundColor: colors.blueAccent[800],
                                },
                                "@media (max-width: 940px)": {
                                    width: "110px",
                                },
                                "@media (max-width: 600px)": {
                                    width: "68px",
                                },
                                //add style to make margin large
                                margin: "10px"
                            }}
                        >
                            <Typography color={colors.blueAccent[100]} sx={{ "@media (max-width: 600px)": { fontSize: "8px", } }}>{bay}</Typography>
                        </Button>
                    ))}
                </Box>
                {/* Bottom empty row */}
                <Box display='flex' alignContent='center' justifyContent='center' margin='50px 50px 25px 50px'>
                    <Button
                        sx={btnStyle2}
                        onClick={handleViewStreamsClick}
                    >
                        <Typography color={colors.blueAccent[100]} variant="h7" sx={text}>
                            View Streams
                        </Typography>
                    </Button>
                </Box>
            </Box>
            <Box>
            </Box>
        </Box>
    );
}

export default BayChooser;

import React, {useEffect} from 'react';
import { Box } from '@mui/material';
import Header from '../../components/Header';
import LocationList from '../../components/Location/LocationList';
import Controllers from '../../components/Controller/Controller';
import CamerasList from '../../components/Camera/CamerasList';
import BayChooser from '../../components/BayChooser';
import TopPanelButtons from '../../components/TopPanelButtons';
import BottomPanelButtons from '../../components/BottomPanelButtons';
import StreamList from '../../components/StreamsList';

import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedLocationId, selectSelectedBay, setSelectedBay, selectSelectedLocationIp} from '../../redux/slices/locationSlice';
import { selectSelectedIds, clearSelectedCamerasButtons, setSelectedIdsByBay} from '../../redux/slices/cameraSlice';
import {refreshCameras, setControllerView, clearSettings } from '../../redux/slices/controllerSlice';
import { logout } from '../../redux/slices/userSlice';
import { setCurrentView, selectCurrentView } from '../../redux/slices/generalSlice';
import { stopStream, setLiveFeed, selectCurrentStreamList, startStream } from '../../redux/slices/streamSlice';
import Notification from '../global/Notification';
import { selectNotificationOpen } from '../../redux/slices/notificationSlice';

const Dashboard = () => {
  const dispatch = useDispatch();
  const selectedCamerasIds = useSelector(selectSelectedIds);
  const selectedLocation = useSelector(selectSelectedLocationId)
  const currentView = useSelector(selectCurrentView);
  const selectedBay = useSelector(selectSelectedBay);
  const currentStreams = useSelector(selectCurrentStreamList);
  const selectedLocationIp = useSelector(selectSelectedLocationIp);
  const NotificationOpen = useSelector(selectNotificationOpen);

  const titles = {
    locations: 'Locations',
    cameras: 'Cameras',
    controllers: 'Controllers',
    streams: 'Streams',
    bays: 'Select Bay'
  }

  const getTitle = () => {
    return titles[currentView];
  }

  const handleEditClick = () => {
    dispatch(setControllerView('general'));
    dispatch(refreshCameras({
      camera_ids: selectedCamerasIds,
      location_id: selectedLocation
    }))
    .unwrap()
    .then(() => {
      dispatch(setCurrentView('controllers'))
    })
    dispatch(setLiveFeed(false));
  };
  
  const handleBackClick = async () => {
    // Function to stop stream if there are any current streams
    const maybeStopStream = () => {
      if (currentStreams.length > 0) {
        dispatch(stopStream({locationId: selectedLocation, cameraIds: selectedCamerasIds}));
      }
    };
  
    // Function to clear selections and settings for cameras
    const clearCameraSelections = () => {
      dispatch(clearSelectedCamerasButtons());
      dispatch(clearSettings());
    };
  
    switch (currentView) {
      case 'cameras':
        // If in CamerasList, go back to LocationList
        dispatch(setCurrentView('locations'));
        break;
      case 'controllers':
        // If in Controllers, go back to CamerasList
        maybeStopStream()
        dispatch(setLiveFeed(false));
        clearCameraSelections();
        break;
      case 'streams':
        // If in StreamsList, go back to CamerasList
        maybeStopStream()
        dispatch(setLiveFeed(false));
        clearCameraSelections();
        dispatch(setSelectedBay(0));
        break;
      case 'bays':
        // If in BayChooser, go back to CamerasList
        dispatch(setSelectedBay(0));
        break;
    }
  
    // Common action for all cases except when currentView is 'cameras'
    if (currentView !== 'cameras') {
      dispatch(setCurrentView('cameras'));
    }
  };

  const handleLogoutClick = () => {
    dispatch(logout());
  };

  const handleMultiStream = () => {
    if (currentView === 'cameras') {
      dispatch(clearSelectedCamerasButtons());
      if (selectedBay === 0) {
        dispatch(setCurrentView('bays'));
      } else {
        dispatch(setSelectedIdsByBay(selectedBay));
        dispatch(setLiveFeed(true));
        dispatch(startStream({locationId: selectedLocation, cameraIds: selectedCamerasIds})).then(() => {
          dispatch(setCurrentView('streams'));
        });
        dispatch(setCurrentView('streams'));
      }
      
    } else {}
  };

  const handleViewStreamsClick = () => {
    dispatch(setLiveFeed(true));       
    dispatch(startStream({ locationId: selectedLocation, cameraIds: selectedCamerasIds })).then(() => {
      dispatch(setCurrentView('streams'));
    });
    dispatch(setCurrentView('streams'));
  };

  return (
    <Box m={4} mx={6}>
      <Box mb="30px">
        <Header title={getTitle()} handleLogout={handleLogoutClick}/>
      </Box>

      <Box mt="30px">
        {currentView !== 'locations' &&  currentView !== 'controllers' && <TopPanelButtons 
          onMultiStream={handleMultiStream}
        />}
      </Box>
      <Box>
        {currentView === 'locations' && <LocationList />}
        {currentView === 'cameras' && <CamerasList />}
        {currentView === 'controllers' && <Controllers handleBackClick={handleBackClick}/>}
        {currentView === 'streams' && <StreamList host={selectedLocationIp}/>}
        {currentView === 'bays' && <BayChooser handleViewStreamsClick={handleViewStreamsClick}/>}
        {!['locations', 'cameras', 'controllers', 'streams', 'bays'].includes(currentView) && <LocationList />}
      </Box>
      {currentView !== 'locations' && currentView !== 'controllers' && (
        <Box mt="30px">
          <BottomPanelButtons 
            onBackClick={handleBackClick}
            onEditClick={handleEditClick}
          />
        </Box>
      )}
      {NotificationOpen && <Notification/>}
    </Box>
  );
};

export default Dashboard;

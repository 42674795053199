import React, { useState } from 'react';
import { tokens } from "../../theme";
import { Box, Button, InputBase, Typography, useTheme, } from "@mui/material";
import logo from "../../assets/Baywatch_Logo.png"
import { useSelector, useDispatch } from 'react-redux';
import { setIsAuthenticated, setIsInitialized, setEmail, setPassword, setUsername, setCurrentUser, setRegistrationToggle, selectRegistrationToggle, selectUsername, selectEmail, selectPassword, login, checkCurrentUser} from '../../redux/slices/userSlice';

const Login = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const email = useSelector(selectEmail);
  const password = useSelector(selectPassword);
  const dispatch = useDispatch();

  const handleEmailChange = (event) => {
    dispatch(setEmail(event.target.value));
  };

  const handlePasswordChange = (event) => {
    dispatch(setPassword(event.target.value));
  };

  const handleLoginClick = () => {
    if ( email && password) {
      dispatch(login({email, password})).then(() => {
        dispatch(checkCurrentUser());
      })
    } else {
      alert("Please enter an email and password");
    }

  };
  const text = {
    "@media (max-width: 840px)": {
      fontSize: "12px"
    },
    "@media (max-width: 400px)": {
      fontSize: "10px"
    }
  }

  return (
    <Box m={2} width={"100%"}>
      <Box display={"flex"} flexDirection={"column"} alignItems={"center"}  >
        <Box my={"120px"} display={"flex"} flexDirection={"column"} gap={"60px"} width={"35%"}>
          <Box display={"flex"} justifyContent={"center"} >
            <img src={logo} alt="logo" />
          </Box>
          <Box display={"flex"} flexDirection={"column"} gap={"10px"} >
            <Box>
              <Typography p={"5px"} variant='h6'>E-mail</Typography>
              <InputBase
                placeholder='Email'
                value={email || ''}
                onChange={handleEmailChange}
                fullWidth
                sx={{
                  border: "1px solid #4B4B4B",
                  borderRadius: "12px",
                  padding: "6px"
                }}
              />
            </Box>
            <Box>
              <Typography p={"5px"} variant='h6'>Password</Typography>
              <InputBase
                type="password"
                placeholder='Password'
                value={password || ""}
                onChange={handlePasswordChange}
                fullWidth
                sx={{
                  border: "1px solid #4B4B4B",
                  borderRadius: "12px",
                  padding: "6px"
                }}
              />
            </Box>
          </Box>
          <Box display={"flex"} justifyContent={"center"} backgroundColor="#1F8FF2" borderRadius="12px" onClick={handleLoginClick} >
            <Button sx={{ color: colors.grey[100], textTransform: "none", width: "100%" }} >
              <Typography variant='h6'>Login</Typography>
            </Button>
          </Box>
        </Box>
        <Box>
          <Box display={"flex"} flexDirection={"column"} alignItems={"center"} gap={"15px"} >
            <Box display={"flex"} sx={{ "@media (max-width: 840px)": { display: "flex", flexDirection: "column", gap: "10px", alignItems: "center" } }}>
              <Box display={"flex"} gap={"20px"} sx={{ "@media (max-width: 840px)": { gap: "10px" } }} >
                <Typography variant='h7' sx={text}> <span>&#8226;</span> Support</Typography>
                <Typography variant='h7' sx={text}> <span>&#8226;</span> Integrations </Typography>
                <Typography variant='h7' sx={text}> <span>&#8226;</span> Forum</Typography>
                <Typography variant='h7' sx={text}> <span>&#8226;</span> Developers & API</Typography>
                <Typography variant='h7' sx={text}> <span>&#8226;</span> Resources</Typography>

              </Box>
              <Box display={"flex"} gap={"20px"} sx={{ "@media (max-width: 840px)": { gap: "10px" } }} >
                <Typography variant='h7' sx={text}> <span>&#8226;</span> Guide</Typography>
                <Typography variant='h7' sx={text}> <span>&#8226;</span> Templates</Typography>
                <Typography variant='h7' sx={text}> <span>&#8226;</span> Terms</Typography>
                <Typography variant='h7' sx={text}> <span>&#8226;</span> Privacy</Typography>
              </Box>
            </Box>
            <Box>
              <Typography variant='h7' sx={text}> This site is protected by reCAPTCHA and the Google Privacy Policy and Terms of Service apply.</Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default Login;

import { Box, Button, useTheme, Typography } from "@mui/material";
import { tokens } from "../theme";
import LocationOnIcon from '@mui/icons-material/LocationOn';
import {selectAll, selectMultiple, selectSelectedButtons, selectSelectAll, selectSelectMultiple } from '../redux/slices/cameraSlice';
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedLocationName } from '../redux/slices/locationSlice';
import { selectCurrentView } from '../redux/slices/generalSlice';


const TopPanelButtons = ({onMultiStream}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const isSelectAll = useSelector(selectSelectAll);
    const selectedButtons = useSelector(selectSelectedButtons);
    const isSelectMultiple = useSelector(selectSelectMultiple);
    const currentLocationName = useSelector(selectSelectedLocationName);
    const currentView = useSelector(selectCurrentView);
  
    

    const handleSelectAll = () => {
        dispatch(selectAll());
    }

    const handleSelectMultiple = () => {
        dispatch(selectMultiple());
    }

    
    const handleStreamView = () => {
      onMultiStream();
    }

    return (
    <Box
        display="flex"
        flexDirection={{ xs: "column", sm: "row" }}
        justifyContent="space-between"
        alignItems="center"
        p={{ xs: "10px", sm: "20px" }}
        sx={{
          backgroundColor: colors.grey[900],
          borderTopLeftRadius: [20],
          borderTopRightRadius: [20],
          borderBottom: "1px solid #585858"
        }}
      >
        <Box display="flex" gap="10px">
          <LocationOnIcon sx={{ color: "#1F8FF2" }} />
          <Typography variant="h4" fontWeight="medium" sx={{ color: colors.grey[100], "@media (max-width: 1080px)": { fontSize: "14px" } }}>
            {currentLocationName}
          </Typography>
        </Box>
        <Box display="flex" gap="10px">
          <Typography variant="h4" fontWeight="medium" sx={{ color: colors.grey[100], "@media (max-width: 1080px)": { fontSize: "14px" } }}>
          {isSelectAll ? "All Cameras" : selectedButtons.join(", ")}
          </Typography> 

        </Box>
        <Box display="flex" gap="35px" sx={{ "@media (max-width: 1080px)": { gap: "15px", my: "10px" } }}>

          <Box display="flex" gap="12px">
          {(currentView !== 'streams' && currentView !== 'bays') && <Button
              onClick={handleStreamView}
              sx={{
                textTransform: "none",
                backgroundColor: "transparent",
                borderRadius: "15px",
                width: "125px",
                border: "1px solid transparent",
                borderColor: colors.blueAccent[100],
                "&:hover": {
                  backgroundColor: colors.blueAccent[800],
                },
                "@media (max-width: 940px)": {
                  width: "110px",
                },
                "@media (max-width: 600px)": {
                  width: "68px",
                }
              }}
            >
              <Typography color={colors.blueAccent[100]} sx={{ "@media (max-width: 600px)": { fontSize: "8px", } }}>Multi-Stream</Typography>
            </Button>}
           {(currentView !== 'streams' && currentView !== 'bays') && <Button onClick={handleSelectMultiple}
              sx={{
                textTransform: "none",
                backgroundColor: isSelectMultiple ? colors.blueAccent[800] : "transparent",
                borderRadius: "15px",
                width: "125px",
                border: "1px solid transparent",
                borderColor: colors.blueAccent[100],
                "&:hover": {
                  backgroundColor: colors.blueAccent[800],
                },
                "@media (max-width: 940px)": {
                  width: "110px",
                },
                "@media (max-width: 600px)": {
                  width: "68px",
                }
              }}
            >
              <Typography color={colors.blueAccent[100]} sx={{ "@media (max-width: 600px)": { fontSize: "8px", } }}>Select Multiple</Typography>
            </Button>}
            {(currentView !== 'streams' && currentView !== 'bays') && <Button onClick={handleSelectAll}
              sx={{
                textTransform: "none",
                width: "125px",
                py: "10px",
                backgroundColor: isSelectAll ? colors.blueAccent[800] : "transparent",
                borderRadius: "15px",
                border: "1px solid transparent",
                borderColor: colors.blueAccent[100],
                "&:hover": {
                  backgroundColor: colors.blueAccent[800],
                },
                "@media (max-width: 940px)": {
                  width: "90px",
                },
                "@media (max-width: 600px)": {
                  width: "10px",
                  py: "1px"
                }
              }}
            >
              <Typography color={colors.blueAccent[100]} sx={{ "@media (max-width: 600px)": { fontSize: "8px", } }}>Select All</Typography>
            </Button>}
          </Box>
        </Box>
      </Box>
    );
  
}

export default TopPanelButtons;
import { Box, Button, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import { useDispatch, useSelector } from 'react-redux';
import { selectSelectedLocationId } from '../redux/slices/locationSlice';
import { resetTimeByLocationId, refreshCameraStatues } from '../redux/slices/cameraSlice';
import { selectCurrentView } from '../redux/slices/generalSlice';
import { selectSelectedIds } from "../redux/slices/cameraSlice";
import { defaultCameras } from "../redux/slices/controllerSlice";
import SyncIcon from '@mui/icons-material/Sync';
import { setNotification } from "../redux/slices/notificationSlice";


const BottomPanelButtons = ({onBackClick, onEditClick}) => {

    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const dispatch = useDispatch();
    const currentLocationId = useSelector(selectSelectedLocationId);
    const currentView = useSelector(selectCurrentView);
    const cameraIds = useSelector(selectSelectedIds);

    const btnStyle = {
      textTransform: "none",
      backgroundColor: "transparent",
      borderRadius: "15px",
      border: "1px solid transparent",
      borderColor: colors.blueAccent[100],
      padding: "6px 30px",
      "@media (max-width: 1080px)": {
          padding: "4px 14px",
      },
      "@media (max-width: 600px)": {
          padding: "4px 5px",
      }
    };
    const text = {
        "@media (max-width: 1080px)": {
            fontSize: "12px"
        }
    }

    const handleResetTime = () => {
        dispatch(resetTimeByLocationId({id: currentLocationId})).then(() => {
          const notification = {
            message: 'Time reset successfully',
            severity: 'success'
          }
          dispatch(setNotification(notification));
        }).catch((error) => {
          const notification = {
            message: 'Time reset failed' + error.message,
            severity: 'error'
          }
          dispatch(setNotification(notification));
        });
    }
    const handleDefaultCameras = () => {
        // if camera id length is zero, then confirm that the user wants to default all cameras
        if (cameraIds.length === 0) {
            if (window.confirm("Are you sure you want to default all cameras?")) {
                dispatch(defaultCameras({locationId: currentLocationId, cameraIds: []})).then(() => {
                  const notification = {
                    message: 'Cameras defaulted successfully',
                    severity: 'success'
                  }
                  dispatch(setNotification(notification));
                }).catch((error) => {
                  const notification = {
                    message: 'Cameras defaulting failed' + error.message,
                    severity: 'error'
                  }
                  dispatch(setNotification(notification));
                });
            }
        } else {
          if (window.confirm("Are you sure you want to default the selected cameras?")) {
            dispatch(defaultCameras({locationId: currentLocationId, cameraIds: cameraIds})).then(() => {
              const notification = {
                message: 'Cameras defaulted successfully',
                severity: 'success'
              }
              dispatch(setNotification(notification));
            }).catch((error) => {
              const notification = {
                message: 'Cameras defaulting failed' + error.message,
                severity: 'error'
              }
              dispatch(setNotification(notification));
            });
          }
        }
    }

    const handleRefreshCameraStatuse = () => {
        dispatch(refreshCameraStatues({locationId: currentLocationId})).then(() => {
          const notification = {
            message: 'Camera statuses refreshed successfully',
            severity: 'success'
          }
          dispatch(setNotification(notification));
        }).catch((error) => {
          const notification = {
            message: 'Camera status refresh failed' + error.message,
            severity: 'error'
          }
          dispatch(setNotification(notification));
        });
    }

    return (
        <Box display={"flex"} justifyContent={"space-between"} p={"25px"} >
          <Box backgroundColor="transparent" borderRadius="12px">
            <Button sx={btnStyle} onClick={onBackClick}>
              <Typography color={colors.blueAccent[100]} variant="h7" sx={text}>
                Back
              </Typography>
            </Button>
          </Box>
          {(currentView === 'cameras' || currentView === 'controllers') && <Box borderRadius="12px">
          <Button sx={{backgroundColor: "#1F8FF2", color: colors.grey[100], textTransform: "none", px: "35px" }} onClick={handleResetTime}>
              Reset Time
          </Button>
            <Button onClick={handleRefreshCameraStatuse}>
              <SyncIcon sx={{color: colors.blueAccent[100], "&:hover": {
                color: colors.grey[200]
              }}} />
            </Button>
            <Button sx={{backgroundColor: "#1F8FF2", color: colors.grey[100], textTransform: "none", px: "35px" }} onClick={handleDefaultCameras}>
              Default Cameras
            </Button>
          </Box>}
          {(currentView === 'cameras' || currentView === 'controllers') && <Box backgroundColor="#1F8FF2" borderRadius="12px">
            <Button sx={{ color: colors.grey[100], textTransform: "none", px: "35px" }} onClick={onEditClick}>
              Edit
            </Button>
          </Box>}
        </Box>
    );
  
}

export default BottomPanelButtons;